import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { withStorageDOMEvents } from '@/contexts/utils/withStorageDOMEvents';
import { demoSetting } from '@/mock/utils/getTagetHospitalSetting';

interface Display {
  row: number;
  column: number;
  cellHeaderSize: number;
  cellHeaderBoxSize: number;
  setRowColumn: (row: number, column: number) => void;
  setCellHeaderSize: (size: number) => void;
  setCellHeaderBoxSize: (size: number) => void;
  resetStore: () => void;
  isAutoAdjustmentMode: boolean;
  setIsAutoAdjustmentMode: (isAutoAdjustmentMode: boolean) => void;
  headerBoxColor: string;
  setHeaderBoxColor: (color: string) => void;
  headerBoxBgColor: string;
  setHeaderBoxBgColor: (color: string) => void;
  headerBoxBgAlpha: number;
  setHeaderBoxBgAlpha: (alpha: number) => void;
  isFitBoxSize: boolean;
  setIsFitBoxSize: (isFitBox: boolean) => void;
}

export const useDisplayStore = create<Display>()(
  devtools(
    persist(
      (set) => ({
        row: demoSetting?.displayLayout.row || 4,
        column: demoSetting?.displayLayout.column || 4,
        cellHeaderSize: demoSetting?.displayLayout.cellHeaderSize || 1,
        cellHeaderBoxSize: 1,
        setRowColumn: (row, column) => set(() => ({ row, column })),
        setCellHeaderSize: (size) => set(() => ({ cellHeaderSize: size })),
        setCellHeaderBoxSize: (size) => set(() => ({ cellHeaderBoxSize: size })),
        resetStore: () => {
          set(() => ({
            row: demoSetting?.displayLayout.row || 4,
            column: demoSetting?.displayLayout.column || 4,
            cellHeaderSize: demoSetting?.displayLayout.cellHeaderSize || 1,
            isAutoAdjustmentMode: false,
          }));
        },
        isAutoAdjustmentMode: false,
        setIsAutoAdjustmentMode: (isAutoAdjustmentMode) => set(() => ({ isAutoAdjustmentMode })),
        headerBoxColor: '#FFFFFF',
        setHeaderBoxColor: (headerBoxColor) => set(() => ({ headerBoxColor })),
        headerBoxBgColor: 'rgba(251, 158, 0, 0.65)',
        setHeaderBoxBgColor: (headerBoxBgColor) => set(() => ({ headerBoxBgColor })),
        headerBoxBgAlpha: 1,
        setHeaderBoxBgAlpha: (headerBoxBgAlpha) => set(() => ({ headerBoxBgAlpha })),
        isFitBoxSize: true,
        setIsFitBoxSize: (isFitBoxSize) => set(() => ({ isFitBoxSize })),
      }),
      { name: 'display-setting', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useDisplayStore);
