import { HYOSUNG_DEFAULT_SETTING } from '../demoSettings/HyosungSettings';
import { OMNIOX_DEFAULT_SETTING } from '../demoSettings/OmnioxSettings';
import { DemoSetting } from '../demoSettings/type';

const getTargetHospitalSetting = (): undefined | DemoSetting => {
  if (process.env.REACT_APP_MODE !== 'demo') {
    return undefined;
  }
  if (process.env.REACT_APP_DEMO_TARGET_HOSPITAL === 'hyosung') {
    return HYOSUNG_DEFAULT_SETTING;
  }
  if (process.env.REACT_APP_DEMO_TARGET_HOSPITAL === 'omniox') {
    return OMNIOX_DEFAULT_SETTING;
  }
  return undefined;
};

export const demoSetting = getTargetHospitalSetting();
