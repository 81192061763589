import axios from 'axios';

import env from '@/env';

// for rest API
const instance = axios.create({
  baseURL: env.REACT_APP_HOST,
  timeout: 60000,
});

const mqttPusherInstance = axios.create({
  baseURL: env.REACT_APP_MQTT_PUSHER_HOST,
  timeout: 15000,
});

export { instance, mqttPusherInstance };
