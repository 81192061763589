/* eslint-disable camelcase */
import { formatISO9075, getHours, getMinutes } from 'date-fns';

import { ResDeviceConnectionList as ResPatient, ResDeviceData } from '@/apis/patient/type';
import { TConnectionInfo } from '@/contexts/patientInfoStore/type';
import { mv50Data } from '@/mock/mv50Data';
import { makeTDevice } from '@/pages/CentralMain/organisms/DataCell/util/makeTDevice';
import { AlarmLevel, Data, DeviceModel } from '@/pages/CentralMain/types';
import makePatientKey from '@/utils/makePatientKey';
import { getRandomNumber } from '@/utils/random';

import { roundNumberToDigit } from '../utils';
import { waveProperty } from '../wave-property';

const UPDATE_STEP = 3;
const SLOW_UPDATE_STEP = UPDATE_STEP * 5;

export class MvSeries {
  static ALARM_SAMPLES: Pick<Data, 'alarms'>['alarms'] = [
    { alarm_msg: 'pi high', alarm_level: 'HIGH' },
    { alarm_msg: 'system fan fail', alarm_level: 'MEDIUM' },
    { alarm_msg: 'open circuit', alarm_level: 'LOW' },
    { alarm_msg: 'low battery', alarm_level: 'LOW' },
  ];

  private getAlarm = (level: AlarmLevel): Pick<Data, 'alarms'>['alarms'] => {
    switch (level) {
      case 'HIGH':
        return MvSeries.ALARM_SAMPLES.slice(0);
      case 'MEDIUM':
        return MvSeries.ALARM_SAMPLES.slice(1);

      case 'LOW':
        return MvSeries.ALARM_SAMPLES.slice(2);
      default:
        return [];
    }
  };

  static DEMO_PARAMS = {
    rr: 18,
    flow: 30,
    fio2: 60,
    spo2: 98,
    pr: 80,
    pi: 5.0,
    airwayTemp: 36,
    peep: 3,
    ppeak: 23.8,
    pmean: 12,
    vti: 500,
    vte: 500,
    fi_peak: 89,
    wobv: 6.5,
    hr: 68,
  };

  static DEMO_PARAMS_RANGE = {
    rr: { min: MvSeries.DEMO_PARAMS.rr - 1, max: MvSeries.DEMO_PARAMS.rr + 1 },
    flow: { min: MvSeries.DEMO_PARAMS.flow - 1, max: MvSeries.DEMO_PARAMS.flow + 1 },
    fio2: { min: MvSeries.DEMO_PARAMS.fio2 - 1, max: MvSeries.DEMO_PARAMS.fio2 + 1 },
    spo2: { min: MvSeries.DEMO_PARAMS.spo2 - 1, max: MvSeries.DEMO_PARAMS.spo2 + 1 },
    pr: { min: MvSeries.DEMO_PARAMS.pr - 2, max: MvSeries.DEMO_PARAMS.pr + 2 },
    pi: { min: MvSeries.DEMO_PARAMS.pi - 1, max: MvSeries.DEMO_PARAMS.pi + 1 },
    airwayTemp: { min: MvSeries.DEMO_PARAMS.airwayTemp - 1, max: MvSeries.DEMO_PARAMS.airwayTemp + 1 },
    peep: { min: MvSeries.DEMO_PARAMS.peep - 1, max: MvSeries.DEMO_PARAMS.peep + 1 },
    ppeak: { min: MvSeries.DEMO_PARAMS.ppeak - 1, max: MvSeries.DEMO_PARAMS.ppeak + 1 },
    vti: { min: MvSeries.DEMO_PARAMS.vti - 1, max: MvSeries.DEMO_PARAMS.vti + 1 },
    vte: { min: MvSeries.DEMO_PARAMS.vti - 15, max: MvSeries.DEMO_PARAMS.vti + 15 },
    fi_peak: { min: MvSeries.DEMO_PARAMS.fi_peak - 1, max: MvSeries.DEMO_PARAMS.fi_peak + 1 },
    wobv: { min: MvSeries.DEMO_PARAMS.wobv - 0.3, max: MvSeries.DEMO_PARAMS.wobv + 0.3 },
    hr: { min: MvSeries.DEMO_PARAMS.hr - 1, max: MvSeries.DEMO_PARAMS.hr + 1 },
  };

  static DEMO_ALARM_PARAMS = {
    rr: { min: Math.round(14 * 0.9), max: Math.round(20 * 1.1) },
    flow: { min: Math.round(30 * 0.9), max: Math.round(50 * 1.1) },
    fio2: { min: Math.round(40 * 0.9), max: Math.round(60 * 1.1) },
    spo2: { min: Math.round(88 * 0.9), max: Math.min(Math.round(95 * 1.1), 100) },
    pr: { min: Math.round(78 * 0.9), max: Math.round(95 * 1.1) },
    pi: { min: Math.round(4.5 * 0.9), max: Math.round(8 * 1.1) },
    airwayTemp: { min: Math.round(36 * 0.9), max: Math.round(44 * 1.1) },
    sfRatio: { min: Math.round(2.45 * 0.9), max: Math.round(4.88 * 1.1) },
    ppeak: { min: Math.round(17 * 0.9), max: Math.round(20 * 1.1) },
    peep: { min: Math.round(10 * 0.9), max: Math.round(20 * 1.1) },
  };

  static DEMO_SETTING_PARAMS = {
    setting_backup_fio2: 50,
    setting_dew_point: 1,
    setting_fio2: 40,
    setting_flow: 30,
    setting_humidification_level: 1,
    setting_rr_detection: 1,
    setting_rr_sensitivity: 1,
    setting_therapy_mode: 1,
    setting_tsf_fio2_high: 80,
    setting_tsf_fio2_low: 10,
    setting_tsf_mode: 1,
    setting_tsf_spo2: 90,
  };

  private manufacturer = '01';

  private model: DeviceModel = 'HFT700';

  private flow: number = MvSeries.DEMO_PARAMS.flow;

  private fio2: number = MvSeries.DEMO_PARAMS.fio2;

  private pr: number = MvSeries.DEMO_PARAMS.pr;

  private rr: number = MvSeries.DEMO_PARAMS.rr;

  private spo2: number = MvSeries.DEMO_PARAMS.spo2;

  private pi: number = MvSeries.DEMO_PARAMS.pi;

  private ppeak: number = MvSeries.DEMO_PARAMS.ppeak;

  private pmean: number = MvSeries.DEMO_PARAMS.pmean;

  private peep: number = MvSeries.DEMO_PARAMS.peep;

  private airwayTemp: number = MvSeries.DEMO_PARAMS.airwayTemp;

  private alarms: Pick<Data, 'alarms'>['alarms'] = [];

  private idSuffix: string;

  private bedId: string;

  private sfRatio: number;

  private rox: number;

  private vti: number = MvSeries.DEMO_PARAMS.vti;

  private vte: number = MvSeries.DEMO_PARAMS.vte;

  private fi_peak: number = MvSeries.DEMO_PARAMS.fi_peak;

  private wobv: number = MvSeries.DEMO_PARAMS.wobv;

  private hr: number = MvSeries.DEMO_PARAMS.hr;

  private slowUpdateOffset = 0;

  private currentUpdateStep = 0;

  private currentIndex: number;

  private deviceAlias: string;

  constructor(
    private index: number,
    private bedIdPrefix: string,
    private alarmLevel: AlarmLevel,
    private deviceModel: DeviceModel,
  ) {
    this.idSuffix = `_${this.index.toString().padStart(2, '0')}`;
    this.bedId = `${this.bedIdPrefix}${this.idSuffix}`;
    this.model = deviceModel;
    this.flow = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.flow.min, MvSeries.DEMO_PARAMS_RANGE.flow.max, 1);
    this.fio2 = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.fio2.min, MvSeries.DEMO_PARAMS_RANGE.fio2.max, 1);
    this.alarms = this.getAlarm(alarmLevel);
    this.sfRatio = (this.spo2 / this.fio2) * 100;
    this.rox = this.sfRatio / this.rr;
    this.currentIndex = 0;
    this.currentUpdateStep = index;
    this.deviceAlias = `BED ${this.index}`;
  }

  private updateParameters() {
    this.currentUpdateStep = (this.currentUpdateStep + 1) % SLOW_UPDATE_STEP;
    if (this.currentUpdateStep % UPDATE_STEP !== 0) {
      return;
    }
    this.peep = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.peep.min, MvSeries.DEMO_PARAMS_RANGE.peep.max, 1);
    this.ppeak = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.ppeak.min, MvSeries.DEMO_PARAMS_RANGE.ppeak.max, 1);
    this.pr = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.pr.min, MvSeries.DEMO_PARAMS_RANGE.pr.max, 1);
    this.rr = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.rr.min, MvSeries.DEMO_PARAMS_RANGE.rr.max, 1);
    this.pi = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.pi.min, MvSeries.DEMO_PARAMS_RANGE.pi.max, 0.1);
    this.spo2 = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.spo2.min, MvSeries.DEMO_PARAMS_RANGE.spo2.max, 1);
    this.airwayTemp = getRandomNumber(
      MvSeries.DEMO_PARAMS_RANGE.airwayTemp.min,
      MvSeries.DEMO_PARAMS_RANGE.airwayTemp.max,
      0.1,
    );
    this.sfRatio = (this.spo2 / this.fio2) * 100;
    this.rox = this.sfRatio / this.rr;
    this.vte = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.vte.min, MvSeries.DEMO_PARAMS_RANGE.vte.max, 1);
    this.fi_peak = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.fi_peak.min, MvSeries.DEMO_PARAMS_RANGE.fi_peak.max, 1);
    this.vti = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.vti.min, MvSeries.DEMO_PARAMS_RANGE.vti.max, 1);
    this.wobv = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.wobv.min, MvSeries.DEMO_PARAMS_RANGE.wobv.max, 0.1);
    this.hr = getRandomNumber(MvSeries.DEMO_PARAMS_RANGE.hr.min, MvSeries.DEMO_PARAMS_RANGE.hr.max, 1);
    if (this.currentUpdateStep % SLOW_UPDATE_STEP === 0) {
      this.slowUpdateOffset = getRandomNumber(-1, 1, 1);
    }
  }

  getPatData(admitIfo: TConnectionInfo, alias: { [key: string]: string }): ResPatient['data'][0] {
    const thisAdmitInfo =
      admitIfo[
        makePatientKey({ manufacturerCode: this.manufacturer, deviceSerial: `DS00${this.idSuffix}`, model: this.model })
      ];
    const thisAlias =
      alias[
        makePatientKey({ manufacturerCode: this.manufacturer, deviceSerial: `DS00${this.idSuffix}`, model: this.model })
      ];
    return {
      acq_time: formatISO9075(Date.now()),
      device_model: this.model,
      bed_id: this.bedId,
      patient_id: thisAdmitInfo?.patId || '',
      device_serial: `DS00${this.idSuffix}`,
      patient_birthdate: thisAdmitInfo?.patBirthdate || '',
      patient_first_name: thisAdmitInfo?.patFirstName || '',
      patient_last_name: thisAdmitInfo?.patLastName || '',
      patient_birthdate_format_code: thisAdmitInfo?.patBirthdateFormatCode || '',
      patient_other_info: thisAdmitInfo?.otherInfo || '',
      patient_race: thisAdmitInfo?.race || '',
      patient_sex: thisAdmitInfo?.sex || '',
      manufacturer: this.manufacturer,
      admit_datetime: thisAdmitInfo?.admitDatetime || '',
      dat: `dat${this.idSuffix}`,
      device_alias: thisAlias,
      pat: thisAdmitInfo?.pat || '',
      discharge_datetime: thisAdmitInfo?.dischargeDatetime || '',
      eat: thisAdmitInfo?.eat || '',
      aat: `AAT${this.idSuffix}`,
      device_active_yn: 'Y',
      ssn: thisAdmitInfo?.ssn || '',
      rox_notifications: [],
    };
  }

  getKey(): string {
    return makePatientKey({
      manufacturerCode: this.manufacturer,
      deviceSerial: `DS00${this.idSuffix}`,
      model: this.model,
    });
  }

  getDeviceData() {
    return {
      dat: `dat${this.idSuffix}`,
      device_model: this.model,
      bed_id: this.bedId,
      device_serial: `DS00${this.idSuffix}`,
      aat: `AAT${this.idSuffix}`,
    };
  }

  getPdpData(): ResDeviceData {
    const { flow } = mv50Data;
    this.updateParameters();
    const now = Date.now();
    this.currentIndex += 1;

    return {
      acq_time: formatISO9075(now),
      device_model: this.model,
      bed_id: this.bedId,
      patient_id: `PID00${this.idSuffix}`,
      device_serial: `DS00${this.idSuffix}`,
      params: {
        ethal: { current: null, max: 3, min: 0.5 },
        fihal: { current: null, max: 3, min: 0.5 },
        ibp1_pr: { current: null, max: null, min: null },
        ibp2_pr: { current: null, max: null, min: null },
        ibp3_pr: { current: null, max: null, min: null },
        ibp4_pr: { current: null, max: null, min: null },
        etdes: { current: null, max: 25.4, min: 25.4 },
        hr: { current: this.hr, max: 300, min: 90 },
        arrhythmia: { current: 0, max: null, min: null },
        etn2o: { current: null, max: 65, min: 30 },
        etenf: { current: null, max: 25.4, min: 25.4 },
        etiso: { current: null, max: 25.4, min: 25.4 },
        etsev: { current: null, max: 25.4, min: 25.4 },
        fico2: { current: 0.1, max: 25.4, min: 25.4 },
        fides: { current: null, max: 25.4, min: 25.4 },
        fienf: { current: null, max: 25.4, min: 25.4 },
        fiiso: { current: null, max: 25.4, min: 25.4 },
        fin2o: { current: null, max: 65, min: 30 },
        fisev: { current: null, max: 25.4, min: 25.4 },
        ibp1_s: { current: 119 + this.slowUpdateOffset, max: 20, min: 5 },
        ibp1_d: { current: 79 + this.slowUpdateOffset, max: 15, min: 0 },
        ibp1_m: { current: 92 + this.slowUpdateOffset, max: 500, min: 500 },
        ibp2_s: { current: 118 + this.slowUpdateOffset, max: 500, min: 500 },
        ibp2_d: { current: 78 + this.slowUpdateOffset, max: 500, min: 500 },
        ibp2_m: { current: 91 + this.slowUpdateOffset, max: 500, min: 500 },
        ibp3_s: { current: 117 + this.slowUpdateOffset, max: 500, min: 500 },
        ibp3_d: { current: 77 + this.slowUpdateOffset, max: 500, min: 500 },
        ibp3_m: { current: 90 + this.slowUpdateOffset, max: 500, min: 500 },
        ibp4_s: { current: 115 + this.slowUpdateOffset, max: 500, min: 500 },
        ibp4_d: { current: 75 + this.slowUpdateOffset, max: 500, min: 500 },
        ibp4_m: { current: 88 + this.slowUpdateOffset, max: 500, min: 500 },
        ico_bt: { current: null, max: 39.6, min: 11 },
        ico_co: { current: null, max: null, min: null },
        ico_paw: { current: null, max: null, min: null },
        ico_pr: { current: null, max: null, min: null },
        nibp_s: { current: 123, max: 160, min: 80 },
        nibp_d: { current: 83, max: 120, min: 50 },
        nibp_m: { current: 96, max: 130, min: 70 },
        nibp_pr: { current: 69, max: 1, min: 1 },
        nibp_time: { current: `[${getHours(now)},${getMinutes(now)}]`, max: null, min: null },
        pvc: { current: 0, max: null, min: null },
        st_i: { current: null, max: -0.16, min: -0.2 },
        st_ii: { current: 0, max: -0.07, min: -0.21 },
        st_iii: { current: null, max: 0.24, min: -0.9 },
        tmp1: { current: 37.5, max: null, min: null },
        tmp2: { current: 37, max: 39.5, min: 35.5 },
        v_delivery: { current: 1, max: 1, min: 1 },
        alarm_status: { current: 1, max: 1, min: 1 },
        peep_h: { current: null, max: null, min: null },
        'p_0.1': { current: null, max: null, min: null },
        auto_peep: { current: null, max: null, min: null },
        cl: { current: null, max: null, min: null },
        e: { current: null, max: null, min: null },
        etco2: { current: null, max: null, min: 0 },
        etco2_rr: { current: null, max: 27, min: 9 },
        fe_peak: { current: -53, max: null, min: null },
        fi_peak: { current: this.fi_peak, max: null, min: null },
        ico2: { current: null, max: 3, min: 1.2 },
        ie_ratio: { current: 1, max: 1, min: 1 },
        peep_l: { current: null, max: null, min: null },
        pmean: {
          current: roundNumberToDigit(6, -1),
          max: null,
          min: null,
        },
        ppause: { current: 23, max: null, min: null },
        ppeak: {
          current: roundNumberToDigit(this.ppeak, -1),
          max: 63,
          min: 3,
        },
        ra: { current: null, max: null, min: null },
        rr_spont: { current: 0, max: null, min: null },
        rsbi: { current: 24, max: null, min: null },
        tc: { current: null, max: null, min: null },
        te: { current: 4.2, max: null, min: null },
        ti: { current: 0.8, max: null, min: null },
        veco2: { current: null, max: null, min: null },
        vico2: { current: null, max: null, min: null },
        vme: { current: 5.9, max: null, min: null },
        vme_s: { current: 0, max: null, min: null },
        vmeco2: { current: 1, max: 1, min: 1 },
        vte: { current: this.vte, max: 1000, min: 300 },
        vti: { current: this.vti, max: null, min: null },
        wobv: { current: roundNumberToDigit(this.wobv, -1), max: null, min: null },
        tsf_status: { current: 1, max: null, min: null },
        paw: { current: 1, max: 1, min: 1 },
        wobp: { current: 1, max: 1, min: 1 },
        exp_flow: { current: 1, max: 1, min: 1 },
        ve_stroke: { current: 1, max: 1, min: 1 },
        h_freq: { current: 1, max: 1, min: 1 },
        amplitude: { current: 1, max: 1, min: 1 },
        flow: {
          current: roundNumberToDigit(this.flow, -1),
          max: roundNumberToDigit(MvSeries.DEMO_ALARM_PARAMS.flow.max, -1),
          min: roundNumberToDigit(MvSeries.DEMO_ALARM_PARAMS.flow.min, -1),
        },
        peep: {
          current: roundNumberToDigit(this.peep, -1),
          max: roundNumberToDigit(8, -1),
          min: roundNumberToDigit(0, -1),
        },
        fio2: {
          current: roundNumberToDigit(21, 0),
          max: roundNumberToDigit(101, 0),
          min: roundNumberToDigit(20, 0),
        },
        pip: {
          current: 1,
          max: 1,
          min: 1,
        },
        estimated_tidal_volume: {
          current: 1,
          max: 1,
          min: 1,
        },
        rr: {
          current: roundNumberToDigit(12, 0),
          max: roundNumberToDigit(27, 0),
          min: roundNumberToDigit(9, 0),
        },
        spo2: {
          current: null,
          max: 101,
          min: 85,
        },
        pr: {
          current: null,
          max: 150,
          min: 50,
        },
        airway_temp: {
          current: roundNumberToDigit(this.airwayTemp, -1),
          max: roundNumberToDigit(MvSeries.DEMO_ALARM_PARAMS.airwayTemp.max, -1),
          min: roundNumberToDigit(MvSeries.DEMO_ALARM_PARAMS.airwayTemp.min, -1),
        },
        ptif: {
          current: 1,
          max: 1,
          min: 1,
        },
        pi: {
          current: roundNumberToDigit(this.pi, -1),
          max: 'Off',
          min: roundNumberToDigit(0.04, -2),
        },
        sf_ratio: {
          current: roundNumberToDigit(this.sfRatio, -1),
          max: null,
          min: null,
        },
        rox: {
          current: roundNumberToDigit(this.rox, -1),
          max: null,
          min: null,
        },
        vent_run: {
          current: '1',
          max: null,
          min: null,
        },
        nibp_status: {
          current: '0',
          max: null,
          min: null,
        },
        map: {
          current: 1,
          max: 1,
          min: 1,
        },
        fpeak: {
          current: 1,
          max: 1,
          min: 1,
        },
        spont_resp: {
          current: 1,
          max: 1,
          min: 1,
        },
        leak_high: {
          current: 1,
          max: 1,
          min: 1,
        },
        total_leak_high: {
          current: 1,
          max: 1,
          min: 1,
        },
        mv: {
          current: 1,
          max: 1,
          min: 1,
        },
      },
      settings: {
        setting_hfv_peep: '15',
        setting_ppause: 1,
        setting_h_freq: '10',
        setting_ecg_cable: '2',
        setting_ibp1_mode: '1',
        setting_ibp2_mode: '2',
        setting_ibp3_mode: '3',
        setting_ibp4_mode: '4',
        setting_backup_fio2: getRandomNumber(
          MvSeries.DEMO_SETTING_PARAMS.setting_fio2 - 20,
          MvSeries.DEMO_SETTING_PARAMS.setting_fio2 + 20,
          1,
        ),
        setting_dew_point: 1,
        setting_fio2: 40,
        setting_flow: roundNumberToDigit(
          getRandomNumber(
            MvSeries.DEMO_SETTING_PARAMS.setting_flow - 20,
            MvSeries.DEMO_SETTING_PARAMS.setting_flow + 20,
            0.1,
          ),
          -1,
        ),
        setting_humidification_level: 1,
        setting_rr_detection: 1,
        setting_therapy_mode: '9', // PRVC
        setting_tsf_fio2_high: getRandomNumber(
          MvSeries.DEMO_SETTING_PARAMS.setting_tsf_fio2_high - 5,
          MvSeries.DEMO_SETTING_PARAMS.setting_tsf_fio2_high + 5,
          1,
        ),
        setting_tsf_fio2_low: getRandomNumber(
          MvSeries.DEMO_SETTING_PARAMS.setting_tsf_fio2_low - 5,
          MvSeries.DEMO_SETTING_PARAMS.setting_tsf_fio2_low + 5,
          1,
        ),
        setting_tsf_mode: '0',
        setting_tsf_spo2: getRandomNumber(
          MvSeries.DEMO_SETTING_PARAMS.setting_tsf_spo2 - 5,
          MvSeries.DEMO_SETTING_PARAMS.setting_tsf_spo2 + 5,
          1,
        ),
        'setting_pa+': '--',
        'setting_pa-': '--',
        setting_adaptive_flow: '--',
        setting_assist_flow: '--',
        setting_auto_start: '--',
        setting_backup_apnea_mode: '--',
        setting_backup_exsens: '--',
        setting_backup_fend: '--',
        setting_backup_pinsp: '--',
        setting_backup_plimit: '--',
        setting_backup_rr: '--',
        setting_backup_ti: '--',
        setting_backup_tinsp: '--',
        setting_backup_tpause: '--',
        setting_backup_trise: '--',
        setting_backup_vti: '--',
        setting_base_flow: '--',
        setting_bed_id: '--',
        setting_biflow_mode: '--',
        setting_cpap: '--',
        setting_demo_mode: '--',
        setting_epap: '--',
        setting_apnea: '--',
        setting_exsens: 0,
        setting_exsens_psv: '--',
        setting_f_trig: 3,
        setting_fend: '--',
        setting_flow_limit: '--',
        setting_inspiratory_time_ti: '--',
        setting_inspiratory_volume_vi: '--',
        setting_ipap: '--',
        setting_minvol: '--',
        setting_p_limit: '--',
        setting_p_trig: '--',
        setting_patient_height: '--',
        setting_patient_id: '--',
        setting_patient_type: '--',
        setting_peep: 3,
        setting_peep_h: '--',
        setting_peep_l: '--',
        setting_pinsp: '--',
        setting_plimit: '50',
        setting_ps: '--',
        setting_rise_time: '--',
        setting_rr: 12,
        setting_rr_sens: '--',
        setting_sigh: '--',
        setting_st_type: '--',
        setting_th_peep: '--',
        setting_tinsp: 0.8,
        setting_tl_peep: '--',
        setting_tpause: '--',
        setting_trigger: '--',
        setting_trigger_level: '--',
        setting_trigger_type: '--',
        setting_trise: 0.2,
        setting_trise_psv: '--',
        setting_tsf_time_const_dec: '--',
        setting_tsf_time_const_inc: '--',
        setting_volume_guarantee: '--',
        setting_vti: 500,
        alarm_apnea: '--',
        alarm_check_refill_water: '--',
        alarm_leakage_high: '--',
        setting_flimit: null,
        setting_imv_etime: null,
        setting_map: null,
        setting_power: null,
        setting_trigger_mode: '1',
        setting_b_guide: null,
        setting_b_interval: null,
        setting_ps_h: null,
        setting_ps_l: null,
        setting_patient_weight: '79',
        setting_resp_lead: '1',
        setting_fpeak: '30',
        setting_nibp_run: '0',
        setting_backup_ipap: null,
        setting_max_pressure: null,
      },
      alarms: this.alarms,
      flow_wave: flow,
      man_code: '01',
      protocol_ver: '1.1',
      rox_notification: [],
      wave_signal_property: waveProperty[makeTDevice(this.deviceModel)],
    };
  }
}
