const env: typeof window._env_ =
  process.env.NODE_ENV === 'development'
    ? {
        REACT_APP_HOST: 'http://192.168.200.210:8080/api',
        REACT_APP_MQTT_PUSHER_HOST: 'http://192.168.200.210:8082/api',
        REACT_APP_MQTT_BROKER_URL: 'wss://central-mqtt.mekbion.io:9001',
        REACT_APP_MQTT_TOPIC: 'pusher/data-each',
        REACT_APP_NO_LOG: 'false',
        // 다른 개발용 runtime 환경 변수들...
      }
    : // eslint-disable-next-line no-underscore-dangle
      window._env_ || {
        REACT_APP_NO_LOG: 'true',
      };

export default env;
