import {
  HFTTrendData,
  MPTrendData,
  MV50TrendData,
  MV2000TrendData,
  ECG_PARAMETER_KEYS,
  IBP_PARAMETER_KEYS,
} from '@/pages/CentralMain/organisms/TrendModal/constants';

import { trendData } from './trendData';

export const HFTTrendDatas: { [k in keyof HFTTrendData]: number[] } = {
  rox: trendData.rox,
  spo2: trendData.spo2,
  sf_ratio: [
    213, 275, 288, 214.5, 195.5, 148, 153, 307, 296, 238, 323, 287, 242, 245, 211, 260.5, 253, 283.5, 197.5, 182.5, 248,
    314, 292.5, 197, 200, 227, 247, 209, 303, 277.5, 239.5, 225, 221, 218, 194, 337.5, 261, 330, 384.5, 303.5, 221,
    274.5, 163, 270, 354, 290, 373, 307, 262, 309, 127, 198, 282.5, 170.5, 233.5, 326.5, 270.5, 303.5, 193.5, 191.5,
    354.5, 214, 158, 194, 321.5, 277.5, 330, 138.5, 337.5, 233.5, 298.5, 200.5, 197.5, 300, 285, 198, 337.5, 109, 313,
    327, 184, 216, 229, 311, 225, 231, 212, 259, 274.5, 143, 163, 238.5, 211.5, 284, 216, 198.5, 186, 265, 288, 145.5,
    251.5, 220, 269, 222.5, 380, 275, 197, 255, 291.5, 201.5, 276.5, 266, 232, 197.5, 302, 289, 207, 207, 238.5, 341.5,
    274, 197, 249, 213.5, 238, 226, 188, 314, 260.5, 201, 167.5, 277, 316, 242.5, 232, 228.5, 283, 315.5, 282, 195,
    227.5, 251.5, 287, 371, 200.5, 263, 233, 315.5, 234, 245.5, 225.5, 316, 215, 265.5, 268.5, 217.5, 133, 205, 275,
    228, 333, 224.5, 275, 255, 304, 193, 279.5, 273.5, 305, 296, 195.5, 287, 183, 280, 307, 194, 241, 260, 360, 264,
    193, 185.5, 213, 231.5, 152, 313, 291.5, 304, 162, 241, 194, 188, 146.5, 157, 316, 308, 340, 261, 151.5, 167, 224,
    251, 347, 180, 270, 254, 244, 293, 302, 267, 154, 220.5, 156, 280, 283, 188, 291.5, 267.5, 194, 243.5, 297, 301,
    160.5, 284.5, 306, 154, 267.5, 293, 207, 187.5, 231, 364, 293, 177, 332, 314.5, 231, 197, 357, 216.5, 298.5, 186,
    152, 282, 239.5, 213, 207.5, 175, 219.5, 268, 229.5, 309.5, 289, 190.5, 275, 169.5, 214, 245, 176.5, 281.5, 258,
    256, 252.5, 198, 318, 229.5, 236, 354, 252, 250.5, 272, 283, 296, 142.5, 221, 245, 286.5, 204.5, 304, 215.5, 288.5,
    247, 280, 215.5, 312.5, 225, 223.5, 325, 212, 186, 215, 263.5, 184.5, 276, 338, 205, 269.5, 304, 222, 263.5, 271,
    237, 206.5, 194.5, 221.5, 299.5, 194, 182.5, 224.5, 295.5, 203, 173, 226, 235.5, 317.5, 218.5, 251.5, 253, 126.5,
    206.5, 249.5, 289, 248, 263.5, 339, 219.5, 158.5, 226, 188, 168, 152, 274, 248, 104.5, 221, 248.5, 277, 267.5, 293,
    314.5, 294.5, 315, 343.5, 225, 197, 167, 291.5, 198.5, 223.5, 180, 261, 285.5, 227, 299.5, 251, 266, 270, 243, 283,
    286, 238, 139, 218.5, 227.5, 279, 304, 145, 215, 273, 211.5, 279.5, 271, 197, 306, 267, 198, 170, 227, 335.5, 231.5,
    236.5, 236, 212.5, 193, 149, 297, 267, 319, 191.5, 275.5, 193, 303.5, 283, 258, 170, 253, 255, 226, 273.5, 187,
    302.5, 215, 173, 260, 230.5, 247, 248.5, 181, 278, 244.5, 187, 236.5, 246.5, 217.5, 178.5, 289.5, 198, 271.5, 190.5,
    321.5, 301.5, 233, 212.5, 363.5, 206, 277, 242.5, 318, 223, 312, 237, 249, 258.5, 240.5, 217, 262, 195, 236, 184,
    219.5, 254, 267.5, 290, 267, 255, 277, 293, 211.5, 251,
  ],
  rr: [
    203, 130, 160.5, 193, 158, 142, 133, 139, 193, 138, 98, 180, 205.5, 135, 103.5, 186, 189.5, 171, 191, 134.5, 157,
    71, 120, 153, 138.5, 129, 153.5, 163, 150, 175, 163.5, 200, 131, 136, 146.5, 221, 187, 210, 142.5, 154, 141, 166,
    125, 168, 113, 132.5, 156.5, 198, 143, 147, 152, 151.5, 147, 176, 158.5, 130, 200.5, 166, 125, 108.5, 158, 70,
    170.5, 96.5, 190, 188, 126.5, 152, 145, 127.5, 182.5, 164.5, 141, 122, 146.5, 161.5, 143, 154, 153, 189.5, 144,
    143.5, 153.5, 172, 139, 173, 171, 121, 130.5, 215, 157, 83, 158, 159.5, 140.5, 136.5, 187, 87.5, 184.5, 184, 129,
    134, 101.5, 197.5, 144, 136, 120, 153, 185, 157, 149.5, 149, 176, 159, 189.5, 185.5, 141, 66, 125, 89, 116, 189,
    150, 118.5, 175.5, 102, 213, 168.5, 163, 189, 155, 143, 173, 123, 122, 113, 109.5, 178.5, 128.5, 178.5, 106.5,
    138.5, 138, 124.5, 164, 145, 187, 157.5, 143, 118, 152, 115.5, 190, 128.5, 126.5, 144.5, 187.5, 188.5, 126, 192.5,
    160, 111.5, 165, 218, 185, 179, 95, 145, 117, 162, 122, 134, 137, 107, 161, 190, 149, 168, 147, 173, 154, 144, 152,
    171, 150, 115, 119.5, 96.5, 129, 184.5, 185, 145, 133.5, 164, 191, 187.5, 147.5, 166.5, 104, 189, 200, 187, 140,
    172.5, 140, 152.5, 246, 140.5, 191, 154.5, 146, 154.5, 189, 113, 118, 118, 206.5, 147, 139, 159.5, 111.5, 199,
    184.5, 152.5, 154, 152, 110, 127.5, 126.5, 167, 162, 128, 195.5, 117, 175.5, 132.5, 153, 159.5, 164, 143, 162.5,
    149, 159, 137, 157, 121, 126, 174, 166, 148.5, 143, 174, 141, 195, 122.5, 155, 132, 111, 125, 123, 118, 217, 181,
    138, 179, 197, 127, 179, 142, 170.5, 173.5, 158, 175.5, 78.5, 102, 208, 157.5, 148, 183, 222, 136, 115, 107, 167.5,
    150, 176, 222, 188, 120, 174, 155, 171.5, 189, 87.5, 142, 169, 115.5, 175, 182.5, 121.5, 227.5, 161.5, 209.5, 146.5,
    209.5, 220, 200, 202, 134, 161, 136, 139.5, 197, 127, 142.5, 127, 179, 135.5, 138, 166.5, 166.5, 198, 151.5, 113,
    174.5, 89, 106.5, 217, 195, 77, 205.5, 141, 129, 163, 146.5, 179.5, 176.5, 150, 157, 148.5, 186, 98.5, 187, 144,
    125, 149, 163, 159, 191, 122, 146, 202, 73, 154.5, 162, 151, 203, 136, 157, 207, 175, 170, 170.5, 199, 141, 123,
    165, 132.5, 152, 124, 120, 121, 104.5, 97, 160, 174, 175, 142, 94, 142, 116, 117, 124, 161, 187, 172, 188, 120, 113,
    157.5, 192, 117, 120.5, 114.5, 140.5, 159, 186, 164, 185, 221, 160, 61, 163, 130.5, 199.5, 129.5, 205, 211, 118.5,
    168.5, 200.5, 178.5, 114.5, 100.5, 160, 141, 154.5, 205.5, 113.5, 163.5, 120, 134, 176, 143, 211, 103.5, 156, 224,
    178.5, 121.5, 204, 105.5, 165, 168, 146, 143, 189.5, 194, 120.5, 195, 104, 164, 144, 140, 138, 157, 163, 136, 167,
  ],
  fio2: [
    67, 53.5, 69.5, 53.5, 60, 57, 58, 44, 63, 64, 66.5, 52, 63, 60, 57, 62, 48, 60, 47, 62, 55, 51.5, 68.5, 53, 52,
    64.5, 64.5, 49.5, 70, 54, 67, 56, 63.5, 45, 53.5, 61, 58.5, 68, 45, 61, 54.5, 47, 61.5, 63.5, 69.5, 61, 53, 54, 55,
    68, 71, 52.5, 59, 80, 64.5, 48, 59.5, 55.5, 54, 52.5, 51, 45, 59, 58, 70.5, 62, 55.5, 55.5, 61, 52, 56.5, 58, 72,
    66.5, 63.5, 53.5, 52, 59, 59, 53, 56, 59, 50, 53, 52.5, 56, 49, 54, 50, 56, 56, 48, 62.5, 61.5, 49, 57, 64.5, 66,
    54, 63, 45, 65, 47, 61, 54, 69, 64, 58, 66, 59.5, 53, 73.5, 65, 64, 62.5, 66, 62, 55, 77.5, 64, 63, 57, 69, 56.5,
    65.5, 50, 57, 72, 76, 50, 69.5, 58.5, 42, 61, 68, 57.5, 70, 47.5, 66.5, 64, 57.5, 58, 49.5, 70, 70.5, 64.5, 58.5,
    44.5, 63, 58.5, 51.5, 58, 72, 54.5, 68.5, 58, 58.5, 66, 63, 64, 61.5, 60.5, 57, 69, 77, 73, 65, 81, 70, 61, 65,
    73.5, 65, 61, 70, 58.5, 59.5, 68, 71, 63, 77, 67, 43, 45, 64, 49, 58.5, 46, 53, 53, 61, 73, 67, 56, 46, 42.5, 65,
    67.5, 69.5, 57, 54.5, 53, 53, 59, 73.5, 52, 58.5, 59, 76.5, 56.5, 45, 54.5, 58, 50, 62, 59, 57.5, 51.5, 62.5, 52,
    68.5, 60, 64.5, 55, 82.5, 59.5, 49, 55, 67, 64, 75.5, 67, 61.5, 67.5, 62, 62.5, 67, 62, 57.5, 57.5, 50.5, 63, 63,
    64, 70.5, 64, 44, 74, 63.5, 61, 65.5, 55, 58.5, 66, 66, 62, 63, 65, 61, 46, 51.5, 68, 62, 68, 61, 75, 54, 49, 75,
    54.5, 52, 50, 64, 66, 67, 63.5, 52.5, 60, 60, 70.5, 55.5, 67.5, 46, 54, 47, 62.5, 66.5, 53.5, 51, 61.5, 52.5, 66,
    63, 44.5, 58.5, 49.5, 49, 66, 56, 70, 67.5, 58, 69, 56, 56.5, 58, 65.5, 58.5, 53.5, 60.5, 55.5, 47, 75, 53.5, 59,
    62.5, 56, 69.5, 61, 56, 49.5, 62, 54.5, 57, 53, 47, 62, 60, 58, 58, 65, 54.5, 58, 61, 52, 63, 61, 47.5, 64.5, 58.5,
    58.5, 68, 64, 68.5, 72, 59, 68, 73, 68, 52, 69.5, 75, 52, 60, 55.5, 62, 70, 55.5, 58, 57, 68.5, 66.5, 67, 53, 57,
    60, 55.5, 57.5, 58.5, 50, 75.5, 73, 67, 65, 50, 72, 60, 58, 66, 59.5, 71, 51, 52, 66, 52, 66.5, 65, 62, 48.5, 62,
    50, 56, 56.5, 50.5, 67, 57.5, 55.5, 63, 49, 50, 60, 48.5, 69, 57, 64, 71, 70.5, 56, 65, 54, 57, 66.5, 67.5, 44.5,
    56, 63, 59.5, 55.5, 57.5, 66.5, 56.5, 55, 54.5, 68, 49, 57, 74, 52, 64, 63, 63, 71, 60, 64, 51.5, 53, 53.5, 78,
    67.5, 56.5, 58, 57, 47, 45, 56.5, 55, 45, 70.5, 64,
  ],
  flow: [48, 35, 47, 56, 52, 50, 38, 37, 42, 43],
  pi: [
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
    0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08,
  ],
  peep: [
    46.4, 68.44999999999999, 52.35, 58.8, 47.7, 43.7, 59.9, 52.9, 45.4, 50.8, 59.6, 50.1, 45.1, 46.6, 34.6, 39, 49.8,
    32.25, 50.2, 60.3, 51.8, 50.3, 63.2, 49.45, 49.8, 58, 22.7, 55.2, 27, 40.5, 29, 47.6, 54.05, 60.2, 67.2, 38, 52.15,
    53.3, 44.9, 41.7, 32.3, 57.9, 68.8, 57.95, 60.1, 37.6, 27.5, 43.1, 45.75, 32, 57.5, 40.849999999999994, 42.25, 35.3,
    52.150000000000006, 46.400000000000006, 46, 40.1, 59.5, 44.8, 50.35, 47.2, 47.75, 70.25, 59.9, 67.45, 55.1, 57.15,
    37.15, 52.95, 57.05, 46.7, 44.05, 60.900000000000006, 52.900000000000006, 48.2, 53.3, 46.5, 35.2, 57.35, 70.7, 76,
    52, 60.8, 36.25, 45.75, 52.25, 42.9, 45.1, 60.6, 45.25, 45.9, 43.9, 29.65, 39.2, 43.15, 47.95, 41.05, 49.05, 57.45,
    59.8, 37.75, 72.7, 62.6, 57.35, 37.6, 29.9, 62.7, 58.3, 48.7, 57.099999999999994, 60.3, 46.55, 34.1, 37.05, 35.8,
    34.75, 47.8, 48.4, 35, 50.150000000000006, 42.6, 47.85, 63.6, 41.85, 59.3, 46.2, 48.5, 33.9, 58.85, 32.2, 40.3,
    66.9, 42.6, 45.099999999999994, 56.7, 54.85, 43.2, 40.45, 45.45, 64.05000000000001, 41, 34.5, 45.75, 40.05, 44.5,
    36.75, 45.2, 37.95, 58.3, 53.95, 49.05, 46.8, 56.25, 38.2, 44.5, 57.65, 67.30000000000001, 59.6, 59.9, 60.35, 45.8,
    71.8, 27.3, 62, 35.849999999999994, 38.599999999999994, 46.5, 59.5, 51.6, 36.9, 47.7, 49.6, 42.9, 47.1, 56.2, 43.4,
    43.3, 36.2, 49.25, 48.9, 38.7, 45.8, 30.4, 46.3, 37.9, 47.75, 65.7, 60.6, 66.9, 71.5, 59.75, 53.4, 45.1, 61.1, 55,
    35.25, 46.400000000000006, 36.75, 34.6, 37, 38.8, 58.15, 58.1, 37.2, 48.3, 52.900000000000006, 39.8, 41, 40, 52.9,
    52.7, 39.3, 44.55, 45.45, 59.7, 42.849999999999994, 50.900000000000006, 47.5, 44.599999999999994, 36.8, 32.75, 73.9,
    59.7, 44.65, 60.95, 35.55, 46.5, 34.3, 56.1, 40.6, 41.8, 47.7, 51.9, 52.7, 43, 52.7, 48.3, 61.35, 47.45, 46.55,
    50.7, 39.5, 60.4, 56.3, 42.5, 53, 48, 62.75, 65.85, 71.1, 44.2, 44.15, 55.7, 53.3, 58.6, 48.5, 29.1, 53.2, 70.4,
    68.35, 52.8, 57.4, 41.9, 37.8, 62.449999999999996, 74.1, 64.9, 64.6, 68.6, 64.1, 64.19999999999999,
    63.150000000000006, 55.7, 48.8, 47.55, 44.35, 54.25, 42.4, 65.7, 61.55, 57.9, 37.3, 64.35, 56.550000000000004,
    55.95, 36.9, 44.25, 37.9, 43.7, 61.3, 54.45, 29.15, 38.5, 61.3, 49.3, 47.2, 50.9, 34.55, 49.35, 46.55, 35.7, 54.15,
    32.55, 38.95, 54.900000000000006, 42.7, 59.6, 30.4, 64.05, 33.15, 41, 41.55, 44.85, 52.8, 56.75, 39.4, 42.85, 45.75,
    51.35, 59.3, 47.75, 39.150000000000006, 40.95, 43.2, 57.4, 52.099999999999994, 47.8, 59.7, 40.5, 17.6, 46.2, 35.8,
    48.3, 59.35, 43.9, 34.65, 54.9, 52.2, 44, 53.15, 63.6, 56.5, 53.6, 44.1, 57.4, 47.2, 56.3, 63.099999999999994,
    51.150000000000006, 54.9, 51.05, 52.7, 71.6, 37.650000000000006, 49.8, 56, 42.95, 56.2, 49.65, 50.3, 67.1, 27.1,
    39.6, 36.15, 45, 42, 44.25, 49.2, 40.8, 37.6, 46, 29.5, 41.75, 45.85, 63.95, 49.150000000000006, 62.3, 57.3, 54.7,
    57.8, 54.5, 50.8, 34.35, 76.7, 68.7, 69.6, 43.599999999999994, 51.4, 62.6, 55.4, 38.6, 40.8, 65.4, 65.35, 49.3,
    39.9, 43.5, 47.6, 47.75, 59.5, 53.3, 50.75, 60.75, 64.30000000000001, 32.5, 44.35, 48.900000000000006, 30.95, 40.85,
    49.5, 56.2, 83.44999999999999, 68.44999999999999, 42.75, 43.2, 51.55, 36.25, 44.45, 41.85, 58.3, 60.8,
    43.900000000000006, 46.7, 47.3, 54.45, 62.8, 57.3, 31.3, 42.25, 55, 50.5, 55.900000000000006, 61, 51.8,
    59.849999999999994, 54.1, 57.4, 55.1, 39.4, 71.1, 62.1, 41.6, 61.6, 42, 57.3, 62.900000000000006, 31.75, 49.5,
  ],
  pr: [
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
    101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101,
  ],
  estimated_tidal_volume: [
    980, 906, 1432, 1432, 955, 1733, 1559, 1529, 1143, 1169, 1210, 1705, 1200, 1172.5, 1657, 1042, 1547, 1237, 1112.5,
    1559, 1555.5, 1360, 1285, 1648, 1404.5, 1049.5, 1082.5, 1232, 1303, 1115.5, 1306.5, 1305, 1128.5, 1030, 1501.5,
    1207.5, 1239.5, 922, 1360.5, 1079, 1524, 1577, 1319.5, 1716, 1307, 1218, 1333, 1388, 1313, 1116.5, 1400, 986.5,
    1691, 1499.5, 1138.5, 1387, 1322, 1385.5, 1113.5, 1032.5, 1182, 1060.5, 1508, 1450, 1181.5, 1089.5, 1091, 1253.5,
    1087, 1056.5, 1010, 1118, 1279.5, 1283, 1710, 1338, 1581, 1748, 1570, 1045, 902, 1514, 859, 1698, 1237, 1610.5, 803,
    1322, 1564, 1465, 1218.5, 1422, 1358, 1161, 1124, 1425, 1457, 1206, 849.5, 1615, 956.5, 1137, 1739, 1205.5, 754,
    1295, 1459, 1209, 1261, 1117, 1379, 933, 771, 965, 1468, 1119.5, 1671, 1161, 1184, 1202.5, 1347.5, 946, 1070, 1311,
    1189, 1223.5, 864.5, 1101, 1139, 1358.5, 1242, 1283, 1340, 1223, 1408.5, 1422, 1297.5, 1588.5, 1113, 1220, 1550.5,
    1446, 1324, 1218.5, 1239.5, 953, 1381, 1390, 1281.5, 1625, 1307.5, 439.5, 1218, 1201, 1410, 1189.5, 905, 1416.5,
    1566, 765, 1330.5, 1364.5, 1280.5, 879, 727.5, 1076, 1049, 1515.5, 1226, 1322, 1289.5, 1113, 1113, 1238, 1221.5,
    1208, 961.5, 1136.5, 1331.5, 1121.5, 1804, 1462.5, 1608, 1474, 1203, 1674, 1156.5, 1078.5, 1186.5, 1623, 1261, 1066,
    611.5, 1022.5, 928.5, 1132, 1451, 1169, 1242, 1266, 1254, 1464.5, 894, 1284, 1239.5, 948, 898, 740, 1743, 936,
    1640.5, 902, 1067, 1292, 1118, 1121, 1550.5, 1360.5, 1190.5, 1561.5, 1078.5, 775, 1008.5, 1544, 1522.5, 1211,
    1379.5, 1228, 1346, 1170.5, 1311.5, 1189.5, 1054, 1193.5, 1321, 839.5, 1645, 1615, 1297, 1051, 920.5, 1576.5, 897,
    1182.5, 858, 1356.5, 1928, 1256, 1215, 1561, 1089.5, 975, 1103, 1088.5, 1334, 1535, 1090, 934.5, 1239.5, 1203,
    1014.5, 1507, 1300, 997, 765, 987.5, 1347, 1059, 1106, 1669, 1225.5, 1423, 1522.5, 1383.5, 1430, 1079, 1382, 1349.5,
    1054, 963.5, 618, 1455.5, 1107, 1144, 1014.5, 1366, 1124.5, 1081, 1519, 950, 1436, 1656, 1603, 983, 1445, 1226,
    1331, 1439, 856, 900, 1630.5, 1546, 1699, 1654.5, 993, 936.5, 870, 1059.5, 1339, 1371, 820, 1684, 1538.5, 1723,
    1201, 1428.5, 1156.5, 893, 1809.5, 1370, 1469.5, 1381, 1043.5, 1200, 981.5, 604, 925.5, 1092.5, 1394.5, 1081, 900,
    984, 1292, 1371, 975, 1153, 1346.5, 1256, 1523, 1034, 1415, 1346, 1411, 1076, 998.5, 624, 1409, 719, 1291, 1383,
    896, 1405, 1665, 1426.5, 1275, 1504, 1181, 825, 1100, 957, 1458, 1620, 857, 877, 1193, 1888, 779.5, 1428, 1647.5,
    1588, 1377, 1430.5, 1537.5, 1254, 1479.5, 1068, 1216, 1313.5, 1421, 1110.5, 542, 1154, 1475, 1228, 1523, 1465,
    1210.5, 1257, 1245, 1498.5, 538, 1138, 899, 1392, 1154.5, 1413, 1643, 999.5, 1464, 1473, 1570.5, 1304, 1215.5,
    1285.5, 1356, 1159, 1331.5, 1102.5, 1377.5, 970.5, 1924, 924, 1375, 1219.5, 1036, 924.5, 744, 968, 1505, 1552.5,
    955, 1265, 1407, 1005, 1129, 1170.5, 1385.5, 1408.5, 1244, 1318, 1377.5, 1266, 1293, 1548.5, 1482, 1424, 1689, 1654,
    695, 1345, 1079, 1401, 1275.5, 1276, 1332, 1249, 1441, 1156.5, 1285,
  ],
  pip: [
    44.2, 53.1, 64.25, 44.2, 25.7, 40.9, 36.650000000000006, 67.1, 50.9, 38.5, 62.5, 47.099999999999994, 50.3, 65.8,
    49.5, 61.400000000000006, 32.95, 60.849999999999994, 49.8, 57.650000000000006, 35.85, 60.6, 41.35, 32.65, 48.8,
    31.4, 52.8, 66, 44.3, 27.65, 66.75, 50.9, 22.1, 62.75, 40.8, 44.05, 40.25, 33.55, 54.2, 74.6, 72.7, 39.7, 49.4,
    49.5, 64.8, 35.4, 71.8, 43.3, 51.7, 36.3, 45.6, 34.5, 65.85, 50.650000000000006, 47.7, 65.35, 37.5, 34.95, 40.3,
    50.25, 69.4, 43.400000000000006, 35.85, 64.6, 35.7, 53.6, 62.35, 35.5, 57.85, 59.900000000000006, 78.05000000000001,
    43, 44.95, 79.05, 30.75, 49.2, 56.25, 30.8, 45.1, 70.45, 49.8, 41.2, 32.2, 36.25, 48.4, 53.4, 42.9, 39.5,
    52.400000000000006, 50.6, 25.9, 48.4, 60.6, 48.5, 59.6, 36.7, 47.1, 26.9, 56, 58.7, 44.8, 72.9, 50.75, 47.3, 39,
    36.3, 61.1, 62.95, 44.8, 33.8, 67.55, 53.25, 53.55, 31, 55.5, 48.35, 45.8, 32.9, 69.25, 62.05, 47.1, 46.4, 49.9,
    62.05, 59, 52.9, 62.25, 72.6, 71.75, 37.1, 33, 50.8, 44.6, 55.650000000000006, 37.3, 39, 58, 60.45,
    56.349999999999994, 34.2, 52.2, 50.349999999999994, 29.3, 51.55, 52.2, 42.8, 33.400000000000006, 47, 45,
    54.599999999999994, 51.400000000000006, 34.85, 65.80000000000001, 43.4, 50.150000000000006, 60.1, 60.85, 73.45,
    63.4, 36.5, 58.5, 54, 35.8, 41.6, 57, 50.3, 52.1, 46.8, 34.2, 46.25, 47.8, 45.4, 52.55, 30.35, 23.45, 67.1, 31.2,
    52.3, 21.6, 60.4, 62.1, 38, 53.9, 57.8, 46.2, 29, 51.6, 61.45, 54.05, 52, 59.1, 59.6, 54.6, 56, 48.3, 48.9, 55.4,
    68.69999999999999, 52.7, 61.5, 35.650000000000006, 24.15, 38, 50.3, 45.2, 31.6, 57.8, 58.25, 44, 34.55, 44.2, 65.3,
    52.8, 52.6, 52.4, 36, 51.05, 53.8, 73.44999999999999, 71.05000000000001, 41.3, 44.45, 63.8, 46.849999999999994,
    44.2, 59, 51.9, 73.7, 49.1, 35.55, 36.150000000000006, 39.849999999999994, 21.15, 54.150000000000006, 27.85,
    37.849999999999994, 63.05, 50.2, 48.5, 60.6, 70.3, 50.25, 55.75, 38.4, 52.5, 29.799999999999997, 46.8, 62, 58.15,
    48.55, 62, 41.7, 55.15, 51.099999999999994, 55.55, 57.85, 37.400000000000006, 35, 39.6, 68.5, 48,
    44.599999999999994, 37.1, 59.3, 58.599999999999994, 54.4, 59.1, 54.7, 50.1, 69.8, 26.700000000000003, 52.6, 56.6,
    42.1, 50.8, 35.1, 56.1, 28.2, 43.150000000000006, 36.8, 66.7, 60.8, 51.35, 54.7, 35.599999999999994, 44.2, 39.8,
    47.9, 59.95, 59.1, 55, 64.7, 46.25, 74.6, 44.400000000000006, 43.4, 57.9, 59.4, 33, 61.15, 48.9, 43, 35.35, 63.95,
    33.15, 55.7, 40.849999999999994, 52.85, 56.1, 55.650000000000006, 52.599999999999994, 59.3, 40.55, 65.35,
    62.099999999999994, 75.6, 57.7, 48.650000000000006, 49.75, 65.45, 50.2, 46.400000000000006, 60.45, 49.25, 50.85,
    62.2, 43.95, 50.599999999999994, 33.599999999999994, 47.3, 70.9, 49.8, 60.4, 37.6, 56.35, 46.5, 48.9, 47.3, 55.7,
    62.45, 42.4, 53.45, 46, 57, 40.8, 43.3, 46.55, 52.7, 43.9, 40.2, 68.65, 57.7, 36.8, 45.3, 45.7, 53.75, 56.3, 50.4,
    52.9, 37.599999999999994, 41, 55.25, 28.2, 36, 61.1, 47, 48.2, 45.4, 25.2, 59.05, 48.8, 51.7, 61, 47.2, 32.5, 48.2,
    67.8, 52, 66.5, 59.55, 32.75, 60.6, 48.5, 55.5, 42, 62.3, 47.1, 43.099999999999994, 46.5, 40.349999999999994, 34.75,
    49.9, 64.3, 57.3, 57.7, 33.85, 36.75, 59.25, 55.4, 46.5, 35.5, 53.6, 55.7, 49.95, 48.3, 65, 58.9, 44.7, 49.15,
    62.95, 27, 31.45, 69.6, 51.95, 39.45, 47.7, 62.35, 45.5, 42.9, 50.2, 58.65, 40.3, 55.7, 56.5, 51, 61.6, 39.15, 37.1,
    64.6, 47.45, 72.1, 41.8, 56.95, 56.55, 62.1, 46.2, 39.6, 44.75, 50.55, 43.3, 37.349999999999994, 41.9, 36.2, 65.2,
    46.3, 36.25, 50, 43.15, 49.8,
  ],
  v_delivery: [
    913, 1130, 783.5, 942.5, 892, 884, 1707, 1296, 1265, 876, 1357.5, 1156, 974.5, 1575, 1381, 1490, 1632.5, 683.5,
    1653, 1729, 1227, 1244.5, 1401, 1296, 1188, 1251.5, 1413, 1179, 1285, 1426.5, 999, 1124.5, 1104, 1570.5, 1443.5,
    1329.5, 1073.5, 1223, 1323, 1375, 959, 728.5, 1488, 1586, 1375.5, 1709.5, 1505, 1423, 1252.5, 1391.5, 1195, 1412.5,
    1320.5, 1583.5, 1602.5, 1339, 1049.5, 812.5, 1421.5, 1071, 1477, 1404.5, 1511, 1311.5, 1337.5, 1437, 1368.5, 1108,
    1428, 1212, 1215, 801.5, 999, 1105.5, 1277, 1546.5, 1507.5, 1334, 1166.5, 967.5, 1382, 1337, 1370, 1483, 867.5,
    1702, 1562.5, 1046, 1463.5, 1093.5, 1145, 1626, 1483.5, 951, 1081.5, 932, 973, 1480.5, 1610.5, 1107, 949.5, 1165.5,
    1546, 762, 808, 1922, 1339.5, 1441, 1724, 550.5, 1160, 1201, 944, 1136.5, 1290, 1051, 1538, 909.5, 1394, 1450, 1563,
    1418.5, 1580.5, 1523.5, 1395, 1239, 1338, 1130.5, 1247, 992, 1102, 917, 1434, 1136.5, 1510.5, 1173.5, 1158.5, 1510,
    1221, 1594.5, 1110, 1039, 1574.5, 1425, 1110.5, 1608.5, 1290, 694.5, 1226.5, 931.5, 1164.5, 1570, 1154.5, 1242,
    1361.5, 1529, 1112.5, 1818, 1133, 1632, 1095, 1181.5, 713, 1106, 1339.5, 1314, 1631, 1303.5, 1581, 1117, 1457.5,
    1372, 884.5, 1634, 1484, 782, 1050, 1566.5, 1073, 1494, 1016, 972, 1292.5, 754, 989, 1237, 786, 1566, 987, 1986,
    770.5, 1547, 1181, 1428, 1440, 1284, 1469, 1337.5, 1356.5, 853, 986, 1424.5, 1399, 1379, 1256, 1336, 966, 844,
    1472.5, 1537, 1312, 1544.5, 1392, 675, 1234, 1253, 876, 1017.5, 1674.5, 1429.5, 1316.5, 892.5, 1120.5, 1352.5,
    1318.5, 1146, 1266.5, 980.5, 1312, 1325, 972, 988, 1248.5, 1158.5, 1202, 1732.5, 946, 1101.5, 1385.5, 1467.5,
    1225.5, 1542, 807, 1306, 1013.5, 1811, 715, 1255, 1121.5, 1068, 1664, 1159, 1483, 1402, 1151, 1853.5, 1409, 1125,
    1522, 1116.5, 1227, 1454, 1478.5, 1491, 1311, 991, 1639, 1524, 1629, 1142, 718, 1268, 1037, 1475, 1177.5, 1727,
    1165.5, 906.5, 858, 1070, 713, 1034, 1568, 1102, 1482, 1393, 1367, 1060, 934.5, 921.5, 1394.5, 1551.5, 1305.5, 1312,
    890.5, 1238, 1298, 958, 1355.5, 1468, 1391, 1452, 765, 1100.5, 1044, 1337, 1212, 1552.5, 1218.5, 1327, 951.5, 1919,
    963, 1538.5, 1174, 1347.5, 1583, 1334, 1405, 998, 1608.5, 1246.5, 1040, 1330, 833, 1395, 1396, 954, 1013.5, 1543,
    1367, 856, 1293.5, 1558, 1492, 1441, 1113, 916, 1232.5, 1500.5, 1404, 1361, 1386, 1165, 1216.5, 1208, 1590, 1035,
    762, 1365, 1128, 1308, 1248, 1204, 1675, 982, 1479.5, 1503, 967, 1258, 1460, 1500, 1197, 1222.5, 875.5, 943, 1468,
    1303, 1001, 1459, 1404, 796, 717, 1825, 1639, 1378, 1427, 1312, 1761, 1298.5, 1724, 1141.5, 1411.5, 1434, 942,
    1445.5, 1274, 1279.5, 987, 1581.5, 1362, 981, 1653, 1343, 749, 1307, 1092, 1196.5, 976, 1100.5, 1405.5, 990, 843,
    1239, 1355, 1109.5, 1063, 1743.5, 1009.5, 1380.5, 822.5, 1201, 1277, 1305, 1130, 1209.5, 1206.5, 832, 1678, 1110.5,
    1625, 1153, 1460, 1121, 1081, 1220.5, 1273, 1315, 1641, 1361, 765.5, 1849, 1480, 1059, 1085, 1342, 1094, 1207.5,
    1569, 638, 1152, 1568, 827, 1274, 529, 1005.5, 1377, 1521, 1897,
  ],
};

export const MPTrendDatas: { [k in keyof MPTrendData]: number[] } = {
  hr: [70, 68, 68, 69.75, 70, 71.2, 72.1, 71.6, 69.25, 68.9, 67.3, 69.35, 66.3, 70.73, 68.61],
  spo2: [96, 94, 95.25, 96.11, 94.5, 96.76, 95.75, 95.52, 94.48, 94.11, 95.31, 94.55, 95.3, 93.83, 93.6],
  tmp1: [33, 34, 33.5, 34.65, 34.59, 33.77, 34.05, 34.18, 32.71, 33.64, 33.64, 34.47, 34.7, 34.391, 33.03],
  tmp2: [34, 35.2, 34.0, 34.39, 34.21, 33.25, 32.59, 34.43, 34.21, 34.46, 33.2, 33.67, 34.1, 34.26, 34.155],
  rr: [12, 12.4, 11, 12.26, 13.06, 12.3, 12.35, 12.74, 12.33, 11.83, 12.93, 12.16, 12.88, 13.53, 12.78],
  ibp1_s: [
    113, 124.1, 126.2, 114.96, 115.53, 122.85, 118.08, 133.52, 121.35, 113.6, 128.66, 117.41, 110.35, 117.18, 122.2,
  ],
  ibp1_d: [68, 71.9, 68.75, 70.83, 70.31, 68.99, 71.21, 70.16, 71.61, 68.9, 71.15, 68.23, 68.63, 70.63, 69.56],
  ibp1_m: [87, 93.8, 94.78, 93.1, 86.88, 93.66, 94.06, 92.72, 89.98, 88.28, 89.13, 87.0, 87.35, 92.05, 90.15],
  ibp2_s: [
    115, 116, 122.6, 117.7, 126.4, 119.2, 127.08, 120.22, 116.65, 119.08, 117.95, 119.91, 125.83, 120.26, 118.78,
  ],
  ibp2_d: [68, 71.4, 68.75, 70.83, 69.88, 71.66, 72.06, 69.83, 72.38, 71.35, 71.73, 69.26, 66.95, 67.56, 70.36],
  ibp2_m: [87, 89.9, 94.08, 86.75, 89.71, 85.65, 95.95, 89.77, 93.7, 97.16, 91.45, 87.8, 92.51, 89.05, 90.71],
  nibp_s: [112, 116.3, 111, 138, 130, 114, 119, 128, 118],
  nibp_d: [64, 64.0, 64, 70, 61, 70, 64, 61, 75],
  nibp_m: [94, 94.0, 94, 87, 92, 95, 92, 86, 100],

  etco2: [4, 5, 6, 4, 5, 5, 4, 4, 5, 5, 4, 5, 5, 5],
  fico2: [4, 4, 5, 6, 5, 6, 4, 4, 5, 5, 5],
};

export const MV50TrendDatas: { [k in keyof MV50TrendData]: number[] } = {
  ppeak: [],
  ppause: [],
  pmean: [],
  peep: [],
  rr: [],
  rr_spont: [],
  etco2_rr: [],
  vti: [],
  vte: [],
  vme: [],
  fio2: [],
  spo2: [],
  ti: [],
  te: [],
  tc: [],
  flow: [],
  fi_peak: [],
  fe_peak: [],
  peep_h: [],
  peep_l: [],
  vico2: [],
  veco2: [],
  vmeco2: [],
  pi: [],
  pr: [],
  rsbi: [],
  wobv: [],
  cl: [],
  ra: [],
};

export const MV2000TrendDatas: { [k in keyof MV2000TrendData]: number[] } = {
  pmean: [5],
  ppeak: [0],
  peep: [1.3],
  vte: [7],
  rr: [14],
  vme: [0.8],
};

export const ECGWaveData: { [k in (typeof ECG_PARAMETER_KEYS)[number]]: number[] } = {
  ecg_wave_avf: [],
  ecg_wave_avl: [],
  ecg_wave_avr: [],
  ecg_wave_i: [],
  ecg_wave_ii: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    2, 6, 12, 17, 19, 20, 24, 29, 29, 29, 30, 29, 25, 22, 20, 18, 14, 8, 4, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, -2, -5, -12, -18, -15, 33, 67, 103, 133, 150, 142, 115, 82, 45, -9, -17, -17, -10, -3, 1, -3, -3, 0,
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 6, 11, 14, 17, 22, 25, 30, 31,
    36, 40, 44, 44, 46, 49, 51, 52, 54, 57, 57, 57, 57, 56, 55, 51, 48, 46, 44, 39, 35, 33, 29, 23, 19, 14, 9, 6, 2, 0,
    -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2, -2,
    -2, -2, -2, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0,
  ],
  ecg_wave_iii: [],
  ecg_wave_v: [],
};

export const IBPWaveData: { [k in (typeof IBP_PARAMETER_KEYS)[number]]: number[] } = {
  ibp1_wave: [
    -12, 9, 27, 39, 48, 54, 54, 51, 51, 54, 54, 51, 45, 36, 24, 12, 0, -12, -24, -33, -39, -45, -51, -51, -45, -36, -30,
    -30, -30, -36, -45, -54, -63, -72, -81, -93, -108, -120, -129, -138, -147, -153, -159, -165, -171, -177, -183, -189,
    -195, -201, -207, -213, -216, -216, -216, -219, -225, -231, -231, -234, -234, -237, -240, -240, -240, -240, -240,
    -240, -240, -240, -240, -243, -249, -255, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258,
    -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258,
    -258, -258, -258, -258, -258, -255, -249, -237, -216, -183, -144, -108, -78, -54, -33, -12, 9, 27, 39, 48, 54, 54,
    51, 51, 54, 54, 51, 45, 36, 24, 12, 0, -12, -24, -33, -39, -45, -51, -51, -45, -36, -30, -30, -30, -36,
  ],

  ibp2_wave: [
    -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258,
    -258, -255, -249, -237, -216, -183, -144, -108, -78, -54, -33, -12, 9, 27, 39, 48, 54, 54, 51, 51, 54, 54, 51, 45,
    36, 24, 12, 0, -12, -24, -33, -39, -45, -51, -51, -45, -36, -30, -30, -30, -36, -45, -54, -63, -72, -81, -93, -108,
    -120, -129, -138, -147, -153, -159, -165, -171, -177, -183, -189, -195, -201, -207, -213, -216, -216, -216, -219,
    -225, -231, -231, -234, -234, -237, -240, -240, -240, -240, -240, -240, -240, -240, -240, -243, -249, -255, -258,
    -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258,
    -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -258, -255, -249, -237,
    -216, -183, -144, -108, -78, -54, -33,
  ],
};

export const RESPWaveData: number[] = [
  332, 330, 328, 324, 319, 313, 308, 302, 297, 293, 289, 286, 282, 278, 275, 271, 267, 264, 262, 265, 275, 286, 299,
  313, 330, 348, 370, 394, 418, 440, 462, 486, 512, 539, 567, 594, 622, 648, 672, 692, 708, 721, 732, 742, 749, 754,
  758, 760, 760, 756, 749, 740, 731, 719, 707, 692, 675, 659, 644, 629, 613, 596, 580, 565, 552, 539, 528, 519, 510,
  501, 493, 490, 488, 486, 488, 490, 492, 493, 493, 492, 490, 488, 486, 484, 482, 479, 475, 471, 468, 464, 464, 460,
  457, 453, 449, 446, 442, 438, 435, 433, 429, 425, 420, 414, 411, 407, 403, 398, 392, 387, 381, 376, 372, 368, 365,
  361, 357, 352, 341, 335, 332, 330, 328, 324, 319, 313, 308, 302, 297, 293, 289, 286, 282, 278, 275, 271, 267, 264,
  262, 265, 275, 286, 299, 313, 330, 348, 370, 394, 418, 440,
];

export const SPO2WaveData: number[] = [
  613, 596, 580, 565, 552, 539, 528, 519, 510, 501, 493, 490, 488, 486, 488, 490, 492, 493, 493, 492, 490, 488, 486,
  484, 482, 479, 475, 471, 468, 464, 464, 460, 457, 453, 449, 446, 442, 438, 435, 433, 429, 425, 420, 414, 411, 407,
  403, 398, 392, 387, 381, 376, 372, 368, 365, 361, 357, 352, 341, 335, 332, 330, 328, 324, 319, 313, 308, 302, 297,
  293, 289, 286, 282, 278, 275, 271, 267, 264, 262, 265, 275, 286, 299, 313, 330, 348, 370, 394, 418, 440, 462, 486,
  512, 539, 567, 594, 622, 648, 672, 692, 708, 721, 732, 742, 749, 754, 758, 760, 760, 756, 749, 740, 731, 719, 707,
  692, 675, 659, 644, 629, 613, 596, 580, 565, 552, 539, 528, 519, 510, 501, 493, 490, 488, 486, 488, 490, 492, 493,
  493, 492, 490, 488, 486, 484, 482, 479, 475, 471, 468, 464,
];
