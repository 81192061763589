import { DEFAULT_PARAM_SETTING } from '@/constants/data';
import { disconnectDeviceKey } from '@/contexts/mockDataStore';

import { DemoSetting } from './type';

export const OMNIOX_DEFAULT_SETTING: DemoSetting = {
  viewData: {
    main: [
      '01//HFT700//DS00_03',
      '01//HFT700//DS00_06',
      '01//HFT750//DS00_07',
      disconnectDeviceKey,
      '01//HFT750//DS00_08',
      '01//HFT700//DS00_09',
    ],
    viewOnly: [],
  },
  displayLayout: {
    row: 2,
    column: 3,
    cellHeaderSize: 1,
  },
  displayMode: 'wave',
  sortOrder: 'sort-disabled',
  headerTitle: { first: 'deviceAlias', second: undefined, third: undefined },
  paramSetting: {
    ...DEFAULT_PARAM_SETTING,
    HFT700: {
      HF: {
        simple: [
          { type: 'params', property: 'flow' },
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'spo2' },
        ],
        all: [
          { type: 'params', property: 'flow' },
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'airway_temp' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'rox' },
        ],
        wave: [
          { type: 'params', property: 'flow' },
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'airway_temp' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'rox' },
          { type: 'trend', property: 'rox' },
        ],
      },
      HF_tsf: {
        simple: [
          { type: 'params', property: 'flow' },
          { type: 'custom', property: 'fio2_tsf' },
          { type: 'custom', property: 'spo2_tsf' },
        ],
        all: [
          { type: 'params', property: 'flow' },
          { type: 'params', property: 'fio2' },
          { type: 'custom', property: 'fio2_tsf' },
          { type: 'params', property: 'rr' },
          { type: 'custom', property: 'spo2_tsf' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'rox' },
        ],
        wave: [
          { type: 'params', property: 'flow' },
          { type: 'params', property: 'fio2' },
          { type: 'custom', property: 'fio2_tsf' },
          { type: 'params', property: 'rr' },
          { type: 'custom', property: 'spo2_tsf' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'rox' },
          { type: 'trend', property: 'rox' },
        ],
      },
      CPAP: {
        simple: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'peep' },
          { type: 'custom', property: 'pa' },
        ],
        all: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'peep' },
          { type: 'custom', property: 'pa' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'estimated_tidal_volume' },
        ],
        wave: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'peep' },
          { type: 'custom', property: 'pa' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'estimated_tidal_volume' },
          { type: 'waves', property: null },
        ],
      },
      'BI-LEVEL_s': {
        simple: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'pip' },
          { type: 'params', property: 'peep' },
        ],
        all: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'pip' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'estimated_tidal_volume' },
        ],
        wave: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'pip' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'estimated_tidal_volume' },
          { type: 'waves', property: null },
        ],
      },
      'BI-LEVEL_t': {
        simple: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'pip' },
          { type: 'params', property: 'peep' },
        ],
        all: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'pip' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'estimated_tidal_volume' },
        ],
        wave: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'pip' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'estimated_tidal_volume' },
          { type: 'waves', property: null },
        ],
      },
      'BI-LEVEL_s_t': {
        simple: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'pip' },
          { type: 'params', property: 'peep' },
        ],
        all: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'pip' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'estimated_tidal_volume' },
        ],
        wave: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'pip' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'estimated_tidal_volume' },
          { type: 'waves', property: null },
        ],
      },
      'BI-LEVEL_s_v': {
        simple: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'v_delivery' },
          { type: 'params', property: 'peep' },
        ],
        all: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'v_delivery' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'pip' },
        ],
        wave: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'v_delivery' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'pip' },
          { type: 'waves', property: null },
        ],
      },
      'BI-LEVEL_t_v': {
        simple: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'v_delivery' },
          { type: 'params', property: 'peep' },
        ],
        all: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'v_delivery' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'pip' },
        ],
        wave: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'v_delivery' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'pip' },
          { type: 'waves', property: null },
        ],
      },
      'BI-LEVEL_s_t_v': {
        simple: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'v_delivery' },
          { type: 'params', property: 'peep' },
        ],
        all: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'v_delivery' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'pip' },
        ],
        wave: [
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'v_delivery' },
          { type: 'params', property: 'peep' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'pip' },
          { type: 'waves', property: null },
        ],
      },

      HF_bi: {
        simple: [
          { type: 'custom', property: 'flow_biflow' },
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'spo2' },
        ],
        all: [
          { type: 'custom', property: 'flow_biflow' },
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'airway_temp' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'rox' },
        ],
        wave: [
          { type: 'custom', property: 'flow_biflow' },
          { type: 'params', property: 'fio2' },
          { type: 'params', property: 'airway_temp' },
          { type: 'params', property: 'rr' },
          { type: 'params', property: 'spo2' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'rox' },
          { type: 'trend', property: 'rox' },
        ],
      },
      HF_bi_tsf: {
        simple: [
          { type: 'custom', property: 'flow_biflow' },
          { type: 'custom', property: 'fio2_tsf' },
          { type: 'custom', property: 'spo2_tsf' },
        ],
        all: [
          { type: 'custom', property: 'flow_biflow' },
          { type: 'params', property: 'fio2' },
          { type: 'custom', property: 'fio2_tsf' },
          { type: 'params', property: 'rr' },
          { type: 'custom', property: 'spo2_tsf' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'rox' },
        ],
        wave: [
          { type: 'custom', property: 'flow_biflow' },
          { type: 'params', property: 'fio2' },
          { type: 'custom', property: 'fio2_tsf' },
          { type: 'params', property: 'rr' },
          { type: 'custom', property: 'spo2_tsf' },
          { type: 'params', property: 'pr' },
          { type: 'params', property: 'pi' },
          { type: 'params', property: 'rox' },
          { type: 'trend', property: 'rox' },
        ],
      },
    },
  },
  patientInfoSetting: {
    '01//HFT700//DS00_03': {
      bedId: '6F_RW_03',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'dat_03',
      deviceAlias: 'BED 3',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: 'AAT_03',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
    '01//HFT700//DS00_06': {
      bedId: '6F_RW_06',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'dat_06',
      deviceAlias: 'BED 6',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: 'AAT_06',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
    '01//HFT750//DS00_07': {
      bedId: 'ER_07',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'dat_07',
      deviceAlias: 'BED 07',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: 'AAT_07',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
    '01//HFT750//DS00_08': {
      bedId: 'ER_08',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'dat_08',
      deviceAlias: 'BED 08',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: 'AAT_08',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
    '01//HFT700//DS00_09': {
      bedId: 'ER_09',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'dat_09',
      deviceAlias: 'BED 09',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: 'AAT_09',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
    [disconnectDeviceKey]: {
      bedId: 'ER-33',
      admitDatetime: '',
      dischargeDatetime: '',
      dat: 'ABC1234',
      deviceAlias: 'BED 33',
      pat: '',
      patId: '',
      patLastName: '',
      patBirthdate: '',
      patBirthdateFormatCode: '',
      patFirstName: '',
      sex: '',
      otherInfo: '',
      race: '',
      eat: '',
      aat: '',
      deviceActiveYn: 'Y',
      ssn: '',
      isRoxNoti: false,
    },
  },
};
