export function generateRoxSimulation() {
  const roxData = [];
  const initialRespiratoryRate = 16; // 초기 호흡 속도
  const initialOxygenSaturation = 98; // 초기 산소 포화도

  // 초기 ROX 값을 계산합니다.
  const initialRox = initialOxygenSaturation / initialRespiratoryRate + 11;

  // 시뮬레이션 시간 설정 (12시간 동안, 1분 간격)
  const simulationDuration = 7 * 60 + 29; // 분 단위로 계산
  const interval = 1; // 1분 간격

  // 초기 ROX 값을 배열에 추가합니다.
  roxData.push(initialRox);

  // ROX 값을 시뮬레이션합니다.
  for (let t = interval; t <= simulationDuration; t += interval) {
    // 호흡 속도와 산소 포화도에 대한 부드러운 변화를 시뮬레이션합니다.
    const respiratoryRate = initialRespiratoryRate + Math.sin(t / 50) * 3; // sin 함수의 매개변수 조정
    const oxygenSaturation = initialOxygenSaturation - Math.cos(t / 40) * 2; // cos 함수의 매개변수 조정

    // ROX 값을 계산하고 배열에 추가합니다.
    const rox = oxygenSaturation / respiratoryRate + t * 0.0001 + 11;
    roxData.push(rox);
  }

  return roxData;
}
export enum SimulationType {
  ascending,
  descending,
  middle,
}

interface SimulationParams {
  initialValue: number; // 초기값
  minValue: number; // 최소값
  maxValue: number; // 최대값
  fluctuationRange: number; // 랜덤 변동 범위 (예: ±0.1, ±0.2 등)
  simulationDuration: number; // 시뮬레이션 기간 (분 단위, 기본 12시간)
  trendMultiplier: number; // 추세에 따른 변화 비율
}

export function generateSimulationData(type: SimulationType, params: SimulationParams): number[] {
  const data: number[] = [];
  const { initialValue, minValue, maxValue, fluctuationRange, simulationDuration, trendMultiplier } = params;

  // 초기값 설정
  data.push(initialValue);
  let currentValue = initialValue;

  // 시뮬레이션 시간 설정 (분 단위)
  const interval = 1; // 1분 간격

  // 시뮬레이션을 위한 루프
  for (let t = interval; t <= simulationDuration * 60; t += interval) {
    // -fluctuationRange에서 fluctuationRange 사이의 랜덤 변동 값 생성
    const randomFluctuation = (Math.random() - 0.5) * fluctuationRange;

    // 값을 추세와 랜덤 변동성에 따라 조정
    currentValue += trendMultiplier + randomFluctuation;

    // 최소값과 최대값을 벗어나지 않도록 조정
    if (currentValue < minValue) {
      currentValue = minValue;
    } else if (currentValue > maxValue) {
      currentValue = maxValue;
    }

    // 중간 값 처리 (type === middle일 때)
    if (type === SimulationType.middle) {
      if (currentValue < (minValue + maxValue) / 2) {
        currentValue = (minValue + maxValue) / 2;
      }
    }

    // 계산된 값을 배열에 추가
    data.push(currentValue);
  }

  return data;
}

// 시뮬레이션 파라미터 생성 함수 (ROX, SpO2 각각의 파라미터 설정)
export function generateRoxSimulationByType(type: SimulationType) {
  const simulationDuration = 12; // 12시간

  const initialValue = type === SimulationType.ascending ? 5 : 8;
  const changeValue = type === SimulationType.middle ? 3 : 5;
  const trendMultiplier = (changeValue / (simulationDuration * 60)) * (type === SimulationType.ascending ? 1 : -1);

  const param = {
    initialValue,
    minValue: 2,
    maxValue: 8,
    fluctuationRange: 0.2,
    simulationDuration,
    trendMultiplier,
  };
  return generateSimulationData(type, param);
}

export function generateSpO2Simulation(type: SimulationType) {
  const simulationDuration = 12; // 12시간

  const initialValue = type === SimulationType.middle ? 98 : 95;
  const changeValue = type === SimulationType.middle ? 2 : 5;
  const trendMultiplier = (changeValue / (simulationDuration * 60)) * (type === SimulationType.ascending ? 1 : -1);

  const param = {
    initialValue,
    minValue: 85,
    maxValue: 99,
    fluctuationRange: 0.2,
    simulationDuration,
    trendMultiplier,
  };
  return generateSimulationData(type, param);
}
