import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import {
  DEFAULT_COLOR_SETTING,
  DEFAULT_PARAM_SETTING,
  DEFAULT_TREND_SETTING,
  DEFAULT_WAVE_SETTING,
} from '@/constants/data';
import { TParamSetting } from '@/constants/types';
import { VERSION } from '@/constants/version';
import { withStorageDOMEvents } from '@/contexts/utils/withStorageDOMEvents';
import { demoSetting } from '@/mock/utils/getTagetHospitalSetting';
import { CELL_SORTING_OPTION, CellSortingOption } from '@/pages/CentralMain/organisms/SortButton/type';
import { DeviceModel, TTrendSetting, TWaveSetting } from '@/pages/CentralMain/types';

import { TTitle, TSelectableTitle } from './type';

interface TrendViewSetting {
  trendSetting: { [key in DeviceModel]: TTrendSetting };
  setTrendSetting: (model: DeviceModel, newSetting: TTrendSetting) => void;
  waveSetting: { [key in DeviceModel]: TWaveSetting };
  setWaveSetting: (model: DeviceModel, newSetting: TWaveSetting) => void;
}

interface Setting extends TrendViewSetting {
  maxDisplayNum: number;
  paramSetting: TParamSetting;
  colorSetting: { [key: string]: string };
  monoColorMode: string | null;
  setParamSetting: (setting: TParamSetting) => void;
  setMaxDisplayNum: (num: number) => void;
  setColorSetting: (colorSetting: { [key: string]: string }) => void;
  setMonoColorMode: (color: string | null) => void;
  title: TTitle;
  setTitle: (key: keyof TTitle, value: keyof TSelectableTitle | undefined) => void;
  version: number;
  setVersion: (version: number) => void;
  resetParamSetting: () => void;
  isDeviceAlias: boolean;
  setIsDeviceAlias: (isAlias: boolean) => void;
  resetSettings: () => void;
  colorTheme: string;
  setColorTheme: (colorTheme: string) => void;
  sortOrder: CellSortingOption;
  setSortOrder: (sortOrder: CellSortingOption) => void;
  isGroupingEnabled: boolean;
  setIsGroupingEnabled: (value: boolean) => void;
  showAdmitIcon: boolean;
  setShowAdmitIcon: (show: boolean) => void;
  isBpMeanVisible: boolean;
  setIsBpMeanVisible: (value: boolean) => void;
}

const defaultParamSetting = demoSetting?.paramSetting || DEFAULT_PARAM_SETTING;
const defaultTrendSetting: TrendViewSetting['trendSetting'] = DEFAULT_TREND_SETTING;
const defaultWaveSetting: TrendViewSetting['waveSetting'] = DEFAULT_WAVE_SETTING;

export const useSettingStore = create<Setting>()(
  devtools(
    persist(
      (set, get) => ({
        maxDisplayNum: 16,
        setMaxDisplayNum: (num) => set(() => ({ maxDisplayNum: num })),
        paramSetting: defaultParamSetting,
        setParamSetting: (setting) => {
          set(() => ({ paramSetting: { ...setting } }));
        },
        colorSetting: DEFAULT_COLOR_SETTING,
        setColorSetting: (colorSetting) => {
          set(() => ({ colorSetting: { ...colorSetting } }));
        },
        monoColorMode: null,
        setMonoColorMode: (color) => {
          set(() => ({ monoColorMode: color }));
        },
        title: demoSetting?.headerTitle || { first: 'model', second: 'patId', third: 'bedId' },
        setTitle: (key, value) => {
          const { title } = get();
          const newTitle = { ...title, [key]: value };
          set(() => ({ title: newTitle }));
        },
        version: VERSION,
        setVersion: (version: number) => {
          set(() => ({ version }));
        },
        resetParamSetting: () => {
          set(() => ({ paramSetting: defaultParamSetting }));
        },
        isDeviceAlias: false,
        setIsDeviceAlias: (isAlias) => {
          set(() => ({ isDeviceAlias: isAlias }));
        },
        resetSettings: () => {
          set(() => ({
            paramSetting: defaultParamSetting,
            colorSetting: DEFAULT_COLOR_SETTING,
            sortOrder: demoSetting?.sortOrder || CELL_SORTING_OPTION['patient-name-asc'],
            title: demoSetting?.headerTitle || { first: 'model', second: 'patId', third: 'bedId' },
            trendSetting: defaultTrendSetting,
            waveSetting: defaultWaveSetting,
          }));
        },
        colorTheme: '#0a66fa',
        setColorTheme: (color) => {
          set(() => ({ colorTheme: color }));
        },
        sortOrder: demoSetting?.sortOrder || CELL_SORTING_OPTION['patient-name-asc'],
        setSortOrder: (sortOrder: CellSortingOption) => {
          set((state) => ({ ...state, sortOrder }));
        },
        isGroupingEnabled: true,
        setIsGroupingEnabled: (value: boolean) => {
          set((prev) => ({ ...prev, isGroupingEnabled: value }));
        },
        showAdmitIcon: false,
        setShowAdmitIcon: (show) => {
          set((prev) => ({ ...prev, showAdmitIcon: show }));
        },
        isBpMeanVisible: false,
        setIsBpMeanVisible: (value: boolean) => {
          set((prev) => ({ ...prev, isBpMeanVisible: value }));
        },
        trendSetting: defaultTrendSetting,
        setTrendSetting: (model, newSetting) => {
          const { trendSetting } = get();
          const newTrendSetting = { ...trendSetting };
          if (model === 'HFT700' || model === 'HFT750') {
            newTrendSetting.HFT700 = newSetting;
            newTrendSetting.HFT750 = newSetting;
          }
          if (model === 'MP800' || model === 'MP1000NTP' || model === 'MP1300') {
            newTrendSetting.MP800 = newSetting;
            newTrendSetting.MP1000NTP = newSetting;
            newTrendSetting.MP1300 = newSetting;
          }
          newTrendSetting[model] = newSetting;
          set((prev) => ({ ...prev, trendSetting: newTrendSetting }));
        },
        waveSetting: defaultWaveSetting,
        setWaveSetting: (model, newSetting) => {
          const { waveSetting } = get();
          const newWaveSetting = { ...waveSetting };
          if (model === 'HFT700' || model === 'HFT750') {
            newWaveSetting.HFT700 = newSetting;
            newWaveSetting.HFT750 = newSetting;
          }
          if (model === 'MP800' || model === 'MP1000NTP' || model === 'MP1300') {
            newWaveSetting.MP800 = newSetting;
            newWaveSetting.MP1000NTP = newSetting;
            newWaveSetting.MP1300 = newSetting;
          }
          newWaveSetting[model] = newSetting;
          set((prev) => ({ ...prev, waveSetting: newWaveSetting }));
        },
      }),
      { name: 'setting', storage: createJSONStorage(() => localStorage) },
    ),
  ),
);

withStorageDOMEvents(useSettingStore);
