import { TColorSetting } from '@/pages/CentralMain/config/colors';
import { Data, DeviceModel, TTherapyMode, TTrendSetting, TWaveSetting } from '@/pages/CentralMain/types';

import {
  HFTTrendSetting,
  HFTWaveSetting,
  MPTrendSetting,
  MPWaveSetting,
  MV2000TrendSetting,
  MV2000WaveSetting,
  MV50TrendSetting,
  MV50WaveSetting,
} from './trend-setting';
import {
  TDisplayParam,
  TParamSetting,
  TWaves,
  TRealtimeWaveDisplayParam,
  TCustomParamProperty,
  TModeSettingParams,
  TModeParamAlarmSetting,
  TModeAlarmSetting,
  TModeSettingParamsDuo,
} from '../types';

export { PARAMS } from './param';
export { SETTING_PARAMS } from './setting-param';

export const DEFAULT_TREND_SETTING: { [key in DeviceModel]: TTrendSetting } = {
  HFT700: HFTTrendSetting,
  HFT750: HFTTrendSetting,
  HFT751: HFTTrendSetting,
  MP1000NTP: MPTrendSetting,
  MP1300: MPTrendSetting,
  MP800: MPTrendSetting,
  MV2000: MV2000TrendSetting,
  MV50: MV50TrendSetting,
};
export const DEFAULT_WAVE_SETTING: { [key in DeviceModel]: TWaveSetting } = {
  HFT700: HFTWaveSetting,
  HFT750: HFTWaveSetting,
  HFT751: HFTWaveSetting,
  MP1000NTP: MPWaveSetting,
  MP1300: MPWaveSetting,
  MP800: MPWaveSetting,
  MV2000: MV2000WaveSetting,
  MV50: MV50WaveSetting,
};

// Amy: param 과 같은 색상을 보여주고 싶으면 setParam 에 추가
export const WAVE_PARAMS: { [k in TWaves]: TDisplayParam & Partial<TRealtimeWaveDisplayParam> } = {
  pressure_wave: {
    label: 'Pressure',
    unit: 'cmH₂O',
    samplingRate: process.env.REACT_APP_MODE === 'demo' ? 480 : 50,
    baseline: 0,
    yMin: 0,
    yMax: 50,
  },
  flow_wave: {
    label: 'Flow',
    unit: 'L/min',
    samplingRate: process.env.REACT_APP_MODE === 'demo' ? 480 : 50,
    baseline: process.env.REACT_APP_MODE === 'demo' ? 0 : 1200,
    yMin: 500,
    yMax: 1700,
  },
  volume_wave: {
    label: 'Volume',
    unit: 'mL',
    samplingRate: process.env.REACT_APP_MODE === 'demo' ? 480 : 50,
    baseline: 0,
    yMin: 500,
    yMax: 1700,
  },
  patient_flow_wave: { label: 'Patient Flow', unit: '', yMin: 500, yMax: 2000 },
  ptif: { label: 'PTIF', unit: '', yMin: 500, yMax: 2000 },
  ecg_wave_i: { label: 'Ⅰ', unit: '' },
  ecg_wave_ii: { label: 'Ⅱ', unit: '' },
  ecg_wave_iii: { label: 'Ⅲ', unit: '' },
  ecg_wave_avr: { label: 'aVR', unit: '' },
  ecg_wave_avl: { label: 'aVL', unit: '' },
  ecg_wave_avf: { label: 'aVF', unit: '' },
  ecg_wave_v: { label: 'V', unit: '' },
  resp_wave: { label: 'RESP', unit: '', setParam: 'rr' },
  spo2_wave: { label: 'SpO₂', unit: '', setParam: 'spo2' },
  ibp1_wave: { label: 'IBP①', unit: '', setParam: 'ibp1_d' },
  ibp2_wave: { label: 'IBP②', unit: '', setParam: 'ibp2_d' },
  ibp3_wave: { label: 'IBP③', unit: '', setParam: 'ibp2_d' },
  ibp4_wave: { label: 'IBP④', unit: '', setParam: 'ibp4_d' },
  co2_wave: { label: 'CO₂', unit: '' },
  // etco2_wave: { label: 'etCO₂', unit: '' },
} as const;

//  Amy: param setting
// Todo Amy: MV 확인 필요
export const DEFAULT_PARAM_SETTING: TParamSetting = {
  MV50: {
    PRVC: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_vti' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_vti' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
    'V-ACV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_vti' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_vti' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
    'P-ACV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_pinsp' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_pinsp' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
    'PRVC-SIMV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_vti' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_vti' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
    'V-SIMV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_vti' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_vti' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
    'P-SIMV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_pinsp' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_pinsp' },
        { type: 'settings', property: 'setting_rr' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
    BiPhasic: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      // Todo : 확인 필요
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_th_peep' },
        { type: 'settings', property: 'setting_tl_peep' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_th_peep' },
        { type: 'settings', property: 'setting_tl_peep' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
    AUTO: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_minvol' },
        { type: 'settings', property: 'setting_patient_height' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_minvol' },
        { type: 'settings', property: 'setting_patient_height' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
    // Todo Amy : 확인 필요
    CPAP: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_ps' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'settings', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_peep' },
        { type: 'settings', property: 'setting_ps' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'settings', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
    HFNC: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_flow' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'settings', property: null },
        { type: 'settings', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_flow' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'settings', property: null },
        { type: 'settings', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
    tBiLevel: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_vti' },
        { type: 'settings', property: 'setting_ipap' },
        { type: 'settings', property: 'setting_epap' },
        { type: 'settings', property: 'setting_fio2' },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'settings', property: 'setting_vti' },
        { type: 'settings', property: 'setting_ipap' },
        { type: 'settings', property: 'setting_epap' },
        { type: 'settings', property: 'setting_fio2' },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
    },
  },
  MV2000: {
    PRVC: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    'V-ACV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'ppause' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    'P-ACV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    'PRVC-SIMV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    'V-SIMV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'ppause' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    'P-SIMV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    AUTO: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    SPONT: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    CPR: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'ppause' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    tBiLevel: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    AwPRV: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    'TCPL-AC': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    'TCPL-SIMV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    O2Stream: {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'peep' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: null },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'ppeak' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    'S-HFV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'amplitude' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'amplitude' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'h_freq' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'amplitude' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'h_freq' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
    'D-HFV': {
      simple: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'amplitude' },
        { type: 'params', property: 'pmean' },
      ],
      all: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'amplitude' },
        { type: 'params', property: null },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'h_freq' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
      ],
      wave: [
        { type: 'params', property: 'vte' },
        { type: 'params', property: 'vme' },
        { type: 'params', property: 'amplitude' },
        { type: 'params', property: 'pmean' },
        { type: 'params', property: 'h_freq' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: null },
        { type: 'params', property: null },
        { type: 'waves', property: 'pressure_wave' },
        { type: 'waves', property: 'flow_wave' },
        { type: 'waves', property: 'volume_wave' },
      ],
      zoom: [
        { type: 'params', property: 'vti' },
        { type: 'params', property: 'fi_peak' },
        { type: 'params', property: 'exp_flow' },
        { type: 'params', property: 'paw' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'auto_peep' },
        { type: 'params', property: 'p_0.1' },
        { type: 'params', property: 'rr_spont' },
        { type: 'params', property: 'ti' },
        { type: 'params', property: 'te' },
      ],
    },
  },
  HFT700: {
    HF: {
      simple: [
        { type: 'params', property: 'flow' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'spo2' },
      ],
      all: [
        { type: 'params', property: 'flow' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'airway_temp' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'rox' },
      ],
      wave: [
        { type: 'params', property: 'flow' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'airway_temp' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'rox' },
        { type: 'waves', property: 'ptif' },
      ],
    },
    HF_tsf: {
      simple: [
        { type: 'params', property: 'flow' },
        { type: 'custom', property: 'fio2_tsf' },
        { type: 'custom', property: 'spo2_tsf' },
      ],
      all: [
        { type: 'params', property: 'flow' },
        { type: 'params', property: 'fio2' },
        { type: 'custom', property: 'fio2_tsf' },
        { type: 'params', property: 'rr' },
        { type: 'custom', property: 'spo2_tsf' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'rox' },
      ],
      wave: [
        { type: 'params', property: 'flow' },
        { type: 'params', property: 'fio2' },
        { type: 'custom', property: 'fio2_tsf' },
        { type: 'params', property: 'rr' },
        { type: 'custom', property: 'spo2_tsf' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'rox' },
        { type: 'waves', property: 'ptif' },
      ],
    },
    CPAP: {
      simple: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'peep' },
        { type: 'custom', property: 'pa' },
      ],
      all: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'peep' },
        { type: 'custom', property: 'pa' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'estimated_tidal_volume' },
      ],
      wave: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'peep' },
        { type: 'custom', property: 'pa' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'estimated_tidal_volume' },
        { type: 'waves', property: null },
      ],
    },
    'BI-LEVEL_s': {
      simple: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'pip' },
        { type: 'params', property: 'peep' },
      ],
      all: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'pip' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'estimated_tidal_volume' },
      ],
      wave: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'pip' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'estimated_tidal_volume' },
        { type: 'waves', property: null },
      ],
    },
    'BI-LEVEL_t': {
      simple: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'pip' },
        { type: 'params', property: 'peep' },
      ],
      all: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'pip' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'estimated_tidal_volume' },
      ],
      wave: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'pip' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'estimated_tidal_volume' },
        { type: 'waves', property: null },
      ],
    },
    'BI-LEVEL_s_t': {
      simple: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'pip' },
        { type: 'params', property: 'peep' },
      ],
      all: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'pip' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'estimated_tidal_volume' },
      ],
      wave: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'pip' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'estimated_tidal_volume' },
        { type: 'waves', property: null },
      ],
    },
    'BI-LEVEL_s_v': {
      simple: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'v_delivery' },
        { type: 'params', property: 'peep' },
      ],
      all: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'v_delivery' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'pip' },
      ],
      wave: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'v_delivery' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'pip' },
        { type: 'waves', property: null },
      ],
    },
    'BI-LEVEL_t_v': {
      simple: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'v_delivery' },
        { type: 'params', property: 'peep' },
      ],
      all: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'v_delivery' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'pip' },
      ],
      wave: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'v_delivery' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'pip' },
        { type: 'waves', property: null },
      ],
    },
    'BI-LEVEL_s_t_v': {
      simple: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'v_delivery' },
        { type: 'params', property: 'peep' },
      ],
      all: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'v_delivery' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'pip' },
      ],
      wave: [
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'v_delivery' },
        { type: 'params', property: 'peep' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'pip' },
        { type: 'waves', property: null },
      ],
    },

    HF_bi: {
      simple: [
        { type: 'custom', property: 'flow_biflow' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'spo2' },
      ],
      all: [
        { type: 'custom', property: 'flow_biflow' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'airway_temp' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'rox' },
      ],
      wave: [
        { type: 'custom', property: 'flow_biflow' },
        { type: 'params', property: 'fio2' },
        { type: 'params', property: 'airway_temp' },
        { type: 'params', property: 'rr' },
        { type: 'params', property: 'spo2' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'rox' },
        { type: 'waves', property: 'ptif' },
      ],
    },
    HF_bi_tsf: {
      simple: [
        { type: 'custom', property: 'flow_biflow' },
        { type: 'custom', property: 'fio2_tsf' },
        { type: 'custom', property: 'spo2_tsf' },
      ],
      all: [
        { type: 'custom', property: 'flow_biflow' },
        { type: 'params', property: 'fio2' },
        { type: 'custom', property: 'fio2_tsf' },
        { type: 'params', property: 'rr' },
        { type: 'custom', property: 'spo2_tsf' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'rox' },
      ],
      wave: [
        { type: 'custom', property: 'flow_biflow' },
        { type: 'params', property: 'fio2' },
        { type: 'custom', property: 'fio2_tsf' },
        { type: 'params', property: 'rr' },
        { type: 'custom', property: 'spo2_tsf' },
        { type: 'params', property: 'pr' },
        { type: 'params', property: 'pi' },
        { type: 'params', property: 'rox' },
        { type: 'waves', property: 'ptif' },
      ],
    },
  },
  MP1300: {
    default: {
      simple: [
        { type: 'params', property: 'hr' },
        { type: 'params', property: 'spo2' },
        { type: 'custom', property: 'temp' },
      ],
      all: [
        { type: 'params', property: 'hr' },
        { type: 'params', property: 'spo2' },
        { type: 'custom', property: 'temp' },
        { type: 'params', property: 'rr' },
        { type: 'custom', property: 'st' },
        { type: 'custom', property: 'nibp' },
        { type: 'custom', property: 'ibp1' },
        { type: 'custom', property: 'ibp2' },
        { type: 'custom', property: 'co2' },
      ],
      wave: [
        { type: 'params', property: 'hr' },
        { type: 'params', property: 'spo2' },
        { type: 'custom', property: 'temp' },
        { type: 'params', property: 'rr' },
        { type: 'custom', property: 'st' },
        { type: 'custom', property: 'nibp' },
        { type: 'custom', property: 'ibp1' },
        { type: 'custom', property: 'ibp2' },
        { type: 'custom', property: 'co2' },
        { type: 'waves', property: 'ecg_wave_ii' },
        { type: 'waves', property: 'spo2_wave' },
        { type: 'waves', property: 'ibp1_wave' },
      ],
    },
  },
};

export const DEFAULT_COLOR_SETTING: TColorSetting = {
  hr: '#00fa00',
  spo2: '#ED8041',
  rox: '#91F375',
  temp: '#00ffff',
  nibp: '#ff9c44',
  ibp1: '#ff7de0',
  ibp2: '#ff7de0',
  ibp3: '#ff7de0',
  ibp4: '#ff7de0',
  st: '#00fa00',
  ecg: '#00fa00',
  // Amy : 임시 컬러 사용
  pr: '#ff5555',
  flow: '#51f542',
  fio2: '#d93fbf',
  rr: '#8080ff',
  pi: '#ff7f50',
  airway_temp: '#20e8e5',
  ppeak: '#F492D3',
  pmean: '#98FE87',
  pressure_wave: '#FFFF00',
  flow_wave: '#00FFFF',
  patient_flow_wave: '#f2ab11',
  resp_wave: '#8080ff',
};

export const CUSTOM_PARAMS: { [k in TCustomParamProperty]: { label: string; unit: string; decimalPlaces?: number } } = {
  spo2_tsf: { label: 'SpO₂(tsf)', unit: ' ' },
  pa: { label: 'PA', unit: ' ' },
  fio2_tsf: { label: 'FiO₂(tsf)', unit: ' ' },
  flow_biflow: { label: 'Flow(Bi-Flow)', unit: ' ' },
  // pm
  co2: { label: 'CO₂', unit: ' ', decimalPlaces: 1 },
  ibp1: { label: 'IBP①', unit: 'mmHg' },
  ibp2: { label: 'IBP②', unit: 'mmHg' },
  ibp3: { label: 'IBP③', unit: 'mmHg' },
  ibp4: { label: 'IBP④', unit: 'mmHg' },
  nibp: { label: 'NIBP', unit: 'mmHg' },
  st: { label: 'ST', unit: ' ' },
  temp: { label: 'TEMP', unit: ' ' },
};

export const REMOTE_SETTING_PARAMS: (keyof Data['params'])[] = ['fio2', 'flow'];
export type TRemoteSettingParam = (typeof REMOTE_SETTING_PARAMS)[number];

export const THERAPY_MODE: {
  [K in TTherapyMode]: { label: { default: string } & { [model in DeviceModel]?: string } };
} = {
  HF: { label: { default: 'HF' } },
  CPAP: {
    label: { default: 'CPAP' },
  },
  'BI-LEVEL_s': {
    label: { default: 'bi-level(S)' },
  },
  'BI-LEVEL_t': {
    label: { default: 'bi-level(T)' },
  },
  'BI-LEVEL_s_t': {
    label: { default: 'bi-level(S/T)' },
  },
  'BI-LEVEL_s_v': {
    label: { default: 'bi-level(S, V)' },
  },
  'BI-LEVEL_t_v': {
    label: { default: 'bi-level(T, V)' },
  },
  'BI-LEVEL_s_t_v': {
    label: { default: 'bi-level(S/T, V)' },
  },
  HF_tsf: {
    label: { default: 'HF(TSF)' },
  },
  HF_bi_tsf: {
    label: { default: 'HF(TSF, Bi-Flow)' },
  },
  HF_bi: {
    label: { default: 'HF(Bi-Flow)' },
  },
  PRVC: {
    label: { default: 'PRVC' },
  },
  default: {
    label: { default: 'default' },
  },
  'V-ACV': {
    label: { default: 'V-ACV' },
  },
  'P-ACV': {
    label: { default: 'P-ACV' },
  },
  'PRVC-SIMV': {
    label: { default: 'PRVC-SIMV' },
  },
  'V-SIMV': {
    label: { default: 'V-SIMV' },
  },
  'P-SIMV': {
    label: { default: 'P-SIMV' },
  },
  AUTO: {
    label: { default: 'AUTO' },
  },
  SPONT: {
    label: { default: 'SPONT' },
  },
  CPR: {
    label: { default: 'CPR' },
  },
  tBiLevel: {
    label: { default: 'tBiLevel', MV50: 'bi-level' },
  },
  AwPRV: {
    label: { default: 'AwPRV' },
  },
  'TCPL-AC': {
    label: { default: 'TCPL-AC' },
  },
  'TCPL-SIMV': {
    label: { default: 'TCPL-SIMV' },
  },
  O2Stream: {
    label: { default: 'O₂Stream' },
  },
  'S-HFV': {
    label: { default: 'S-HFV' },
  },
  'D-HFV': {
    label: { default: 'D-HFV' },
  },
  BiPhasic: {
    label: { default: 'BiPhasic' },
  },
  HFNC: {
    label: { default: 'HFNC' },
  },
};

// Amy: remote setting 에서 setting 파라미터
export const MODE_SETTING_PARAMS: TModeSettingParams = {
  // Todo amy: 수정 필요
  MV50: {
    PRVC: ['setting_adaptive_flow'],
    'V-ACV': ['setting_adaptive_flow'],
    'P-ACV': ['setting_adaptive_flow'],
    'PRVC-SIMV': ['setting_adaptive_flow'],
    'V-SIMV': ['setting_adaptive_flow'],
    'P-SIMV': ['setting_adaptive_flow'],
    AUTO: ['setting_adaptive_flow'],
    BiPhasic: [],
    CPAP: [],
    HFNC: [],
    tBiLevel: [],
  },
  MV2000: {
    PRVC: ['setting_adaptive_flow'],
    'V-ACV': ['setting_adaptive_flow'],
    'P-ACV': ['setting_adaptive_flow'],
    'PRVC-SIMV': ['setting_adaptive_flow'],
    'V-SIMV': ['setting_adaptive_flow'],
    'P-SIMV': ['setting_adaptive_flow'],
    AUTO: ['setting_adaptive_flow'],
    SPONT: ['setting_adaptive_flow'],
    CPR: ['setting_adaptive_flow'],
    tBiLevel: ['setting_adaptive_flow'],
    AwPRV: ['setting_adaptive_flow'],
    'TCPL-AC': ['setting_adaptive_flow'],
    'TCPL-SIMV': ['setting_adaptive_flow'],
    O2Stream: ['setting_adaptive_flow'],
    'S-HFV': ['setting_adaptive_flow'],
    'D-HFV': ['setting_adaptive_flow'],
  },
  HFT700: {
    HF: [
      'setting_flow',
      'setting_fio2',
      // 'setting_rr_detection',
      // 'setting_dew_point',
      // 'setting_humidification_level',
      // 'setting_tsf_mode',
      // 'setting_biflow_mode',
    ],
    HF_tsf: [
      'setting_flow',
      // 'setting_rr_detection',
      // 'setting_backup_fio2',
      // 'setting_tsf_spo2',
      // 'setting_dew_point',
      // 'setting_humidification_level',
      // 'setting_tsf_mode',
      // 'setting_biflow_mode',
    ],
    CPAP: [
      'setting_cpap',
      'setting_fio2',
      'setting_pa+',
      'setting_pa-',
      // 'setting_trigger_level',
      // 'setting_rise_time',
      // 'setting_auto_start',
      // 'setting_dew_point',
      // 'setting_humidification_level',
    ],
    'BI-LEVEL_s': [
      // 'setting_trigger_type',
      // 'setting_volume_guarantee',
      // 'setting_trigger_level',
      'setting_fio2',
      'setting_ipap',
      'setting_pa-',
      'setting_epap',
      // 'setting_rise_time',
      // 'setting_auto_start',
      // 'setting_dew_point',
      // 'setting_humidification_level',
    ],
    'BI-LEVEL_t': [
      // 'setting_trigger_type',
      // 'setting_volume_guarantee',
      'setting_fio2',
      'setting_ipap',
      'setting_pa-',
      'setting_epap',
      // 'setting_inspiratory_time_ti',
      // 'setting_rr',
      // 'setting_rise_time',
      // 'setting_dew_point',
      // 'setting_humidification_level',
    ],
    'BI-LEVEL_s_t': [
      // 'setting_trigger_type',
      // 'setting_volume_guarantee',
      // 'setting_trigger_level',
      'setting_fio2',
      'setting_ipap',
      'setting_pa-',
      'setting_epap',
      // 'setting_inspiratory_time_ti',
      // 'setting_rr',
      // 'setting_rise_time',
      // 'setting_auto_start',
      // 'setting_dew_point',
      // 'setting_humidification_level',
    ],
    'BI-LEVEL_s_v': [
      // 'setting_trigger_type',
      // 'setting_volume_guarantee',
      // 'setting_trigger_level',
      'setting_fio2',
      // 'setting_inspiratory_volume_vi',
      // 'setting_p_limit',
      'setting_pa-',
      'setting_epap',
      // 'setting_rise_time',
      // 'setting_auto_start',
      // 'setting_dew_point',
      // 'setting_humidification_level',
    ],
    'BI-LEVEL_t_v': [
      // 'setting_trigger_type',
      // 'setting_volume_guarantee',
      'setting_fio2',
      // 'setting_inspiratory_volume_vi',
      // 'setting_p_limit',
      'setting_pa-',
      'setting_epap',
      // 'setting_inspiratory_time_ti',
      // 'setting_rr',
      // 'setting_rise_time',
      // 'setting_dew_point',
      // 'setting_humidification_level',
    ],
    'BI-LEVEL_s_t_v': [
      // 'setting_trigger_type',
      // 'setting_volume_guarantee',
      // 'setting_trigger_level',
      'setting_fio2',
      // 'setting_inspiratory_volume_vi',
      // 'setting_plimit',
      'setting_pa-',
      'setting_epap',
      // 'setting_inspiratory_time_ti',
      // 'setting_rr',
      // 'setting_rise_time',
      // 'setting_auto_start',
      // 'setting_dew_point',
      // 'setting_humidification_level',
    ],
    HF_bi: [
      'setting_flow',
      // 'setting_base_flow',
      // 'setting_assist_flow',
      'setting_fio2',
      // 'setting_rr_detection',
      // 'setting_dew_point',
      // 'setting_humidification_level',
      // 'setting_tsf_mode',
      // 'setting_biflow_mode',
    ],
    HF_bi_tsf: [
      // 'setting_base_flow',
      // 'setting_assist_flow',
      // 'setting_rr_detection',
      // 'setting_backup_fio2',
      // 'setting_tsf_spo2',
      // 'setting_dew_point',
      // 'setting_humidification_level',
      // 'setting_tsf_mode',
      // 'setting_biflow_mode',
    ],
  },
  MP1300: { default: [] },
};

// Amy: remote setting 에서 두가지를 한 번에 보여주는 setting 파라미터
export const MODE_SETTING_PARAMS_DUO: TModeSettingParamsDuo = {
  MV50: {
    PRVC: [],
    'V-ACV': [],
    'P-ACV': [],
    'PRVC-SIMV': [],
    'V-SIMV': [],
    'P-SIMV': [],
    AUTO: [],
    BiPhasic: [],
    CPAP: [],
    HFNC: [],
    tBiLevel: [],
  },
  MV2000: {
    PRVC: [],
    'V-ACV': [],
    'P-ACV': [],
    'PRVC-SIMV': [],
    'V-SIMV': [],
    'P-SIMV': [],
    AUTO: [],
    SPONT: [],
    CPR: [],
    tBiLevel: [],
    AwPRV: [],
    'TCPL-AC': [],
    'TCPL-SIMV': [],
    O2Stream: [],
    'S-HFV': [],
    'D-HFV': [],
  },
  HFT700: {
    HF: [],
    HF_tsf: [
      // { label: 'FiO₂ range', left: 'setting_tsf_fio2_low', right: 'setting_tsf_fio2_high' },
      // { label: 'Time constant', left: 'setting_tsf_time_const_inc', right: 'setting_tsf_time_const_dec' },
    ],
    CPAP: [],
    'BI-LEVEL_s': [],
    'BI-LEVEL_t': [],
    'BI-LEVEL_s_t': [],
    'BI-LEVEL_s_v': [],
    'BI-LEVEL_t_v': [],
    'BI-LEVEL_s_t_v': [],
    HF_bi: [],
    HF_bi_tsf: [
      // { label: 'FiO₂ range', left: 'setting_tsf_fio2_low', right: 'setting_tsf_fio2_high' },
      // { label: 'Time constant', left: 'setting_tsf_time_const_inc', right: 'setting_tsf_time_const_dec' },
    ],
  },
  MP1300: {
    default: [],
  },
};

// Amy: remote setting 에서 alarm setting
export const ALARM_SETTING_PARAMS: TModeParamAlarmSetting = {
  MV50: {
    PRVC: ['flow', 'fio2', 'rr', 'spo2', 'pr', 'pi', 'airway_temp'],
    'V-ACV': [],
    'P-ACV': [],
    'PRVC-SIMV': [],
    'V-SIMV': [],
    'P-SIMV': [],
    AUTO: [],
    BiPhasic: [],
    CPAP: [],
    HFNC: [],
    tBiLevel: [],
  },
  MV2000: {
    PRVC: ['flow', 'fio2', 'rr', 'spo2', 'pr', 'pi', 'airway_temp'],
    'V-ACV': [],
    'P-ACV': [],
    'PRVC-SIMV': [],
    'V-SIMV': [],
    'P-SIMV': [],
    AUTO: [],
    SPONT: [],
    CPR: [],
    tBiLevel: [],
    AwPRV: [],
    'TCPL-AC': [],
    'TCPL-SIMV': [],
    O2Stream: [],
    'S-HFV': [],
    'D-HFV': [],
  },
  HFT700: {
    HF: ['flow', 'fio2', 'rr', 'spo2', 'pr', 'pi', 'airway_temp'],
    HF_tsf: ['flow', 'fio2', 'rr', 'spo2', 'pr', 'pi', 'airway_temp'],
    CPAP: ['fio2', 'pip', 'peep', 'spo2', 'rr', 'pr', 'pi', 'airway_temp'],
    HF_bi: ['flow', 'fio2', 'rr', 'spo2', 'pr', 'pi', 'airway_temp'],
    HF_bi_tsf: ['flow', 'fio2', 'rr', 'spo2', 'pr', 'pi', 'airway_temp'],
    'BI-LEVEL_s': ['fio2', 'pip', 'peep', 'spo2', 'rr', 'pr', 'pi', 'airway_temp'],
    'BI-LEVEL_t': ['fio2', 'pip', 'peep', 'spo2', 'rr', 'pr', 'pi', 'airway_temp'],
    'BI-LEVEL_s_t': ['fio2', 'pip', 'peep', 'spo2', 'rr', 'pr', 'pi', 'airway_temp'],
    'BI-LEVEL_s_v': ['fio2', 'pip', 'peep', 'spo2', 'rr', 'pr', 'pi', 'v_delivery', 'airway_temp'],
    'BI-LEVEL_t_v': ['fio2', 'pip', 'peep', 'spo2', 'rr', 'pr', 'pi', 'v_delivery', 'airway_temp'],
    'BI-LEVEL_s_t_v': ['fio2', 'pip', 'peep', 'spo2', 'rr', 'pr', 'pi', 'v_delivery', 'airway_temp'],
  },
  MP1300: {
    default: [],
  },
};

// Amy : remote setting 에서 single 유형의 alarm setting
export const ALARM_SETTINGS: TModeAlarmSetting = {
  MV50: {
    PRVC: ['alarm_apnea', 'alarm_check_refill_water'],
    'V-ACV': [],
    'P-ACV': [],
    'PRVC-SIMV': [],
    'V-SIMV': [],
    'P-SIMV': [],
    AUTO: [],
    BiPhasic: [],
    CPAP: [],
    HFNC: [],
    tBiLevel: [],
  },
  MV2000: {
    PRVC: ['alarm_apnea', 'alarm_check_refill_water'],
    'V-ACV': [],
    'P-ACV': [],
    'PRVC-SIMV': [],
    'V-SIMV': [],
    'P-SIMV': [],
    AUTO: [],
    SPONT: [],
    CPR: [],
    tBiLevel: [],
    AwPRV: [],
    'TCPL-AC': [],
    'TCPL-SIMV': [],
    O2Stream: [],
    'S-HFV': [],
    'D-HFV': [],
  },
  HFT700: {
    HF: ['alarm_apnea', 'alarm_check_refill_water'],
    HF_tsf: ['alarm_apnea', 'alarm_check_refill_water'],
    CPAP: ['alarm_apnea', 'alarm_check_refill_water', 'alarm_leakage_high'],
    HF_bi: ['alarm_apnea', 'alarm_check_refill_water'],
    HF_bi_tsf: ['alarm_apnea', 'alarm_check_refill_water'],
    'BI-LEVEL_s': ['alarm_apnea', 'alarm_check_refill_water', 'alarm_leakage_high'],
    'BI-LEVEL_t': ['alarm_check_refill_water', 'alarm_leakage_high'],
    'BI-LEVEL_s_t': ['alarm_apnea', 'alarm_check_refill_water', 'alarm_leakage_high'],
    'BI-LEVEL_s_v': ['alarm_apnea', 'alarm_check_refill_water', 'alarm_leakage_high'],
    'BI-LEVEL_t_v': ['alarm_check_refill_water', 'alarm_leakage_high'],
    'BI-LEVEL_s_t_v': ['alarm_apnea', 'alarm_check_refill_water', 'alarm_leakage_high'],
  },
  MP1300: {
    default: [],
  },
};
