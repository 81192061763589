// Amy : type key로 정의하기 위해 제거
const languages = {
  fullScreen: { en: 'Full Screen', ko: '전체 화면' },
  id: { en: 'ID', ko: '아이디' },
  requiredId: { en: 'ID is required.', ko: '아이디는 필수 입력 사항입니다.' },
  pw: { en: 'PW', ko: '비밀번호' },
  requiredPw: { en: 'Password is required.', ko: '비밀번호는 필수 입력 사항입니다.' },
  login: { en: 'Login', ko: '로그인' },
  about: { en: 'About', ko: '제품 정보' },
  version: { en: 'version', ko: '버전' },
  buildNumber: { en: 'Build Number', ko: '빌드 번호' },
  close: { en: 'Close', ko: '닫기' },
  currentActivatedAlarmList: { en: 'Current Activated Alarm List', ko: '현재 활성화 알람 목록' },
  alarmHistory: { en: 'Alarm History', ko: '알람 이력' },
  firstOccurrenceTime: { en: 'First Occurrence Time', ko: '최초 발생 시간' },
  lastOccurrenceTime: { en: 'Last Occurrence Time', ko: '최종 발생 시간' },
  message: { en: 'Message', ko: '메세지' },
  level: { en: 'Level', ko: '레벨' },
  patientId: { en: 'Patient ID', ko: '환자 아이디' },
  bedId: { en: 'Bed ID', ko: '침대 번호' },
  deviceId: { en: 'Device ID', ko: '기기 아이디' },
  apply: { en: 'Apply', ko: '확인' },
  colorSetting: { en: 'Color Setting', ko: '색상 설정' },
  customTheme: { en: 'Custom Theme', ko: '커스텀 색상' },
  monoTheme: { en: 'Mono Color Theme', ko: '단색 적용' },
  searchByParameterName: { en: 'Search by parameter name', ko: '파라미터 이름으로 검색' },
  cancel: { en: 'Cancel', ko: '취소' },
  move: { en: 'Move', ko: '이동' },
  remove: { en: 'Remove', ko: '삭제' },
  remoteSetting: { en: 'Remote Setting', ko: '원격 설정' },
  paramSetting: { en: 'Param Setting', ko: '파라미터 설정' },
  displaySetting: { en: 'Display Setting', ko: '디스플레이 설정' },
  simple: { en: 'Simple', ko: '심플' },
  all: { en: 'All', ko: '전체' },
  withWave: { en: 'with Wave', ko: '웨이브' },
  signageMode: { en: 'Signage Mode', ko: '사이니지 모드' },
  signageModeOnOff: { en: 'Signage Mode On / Off', ko: '사이니지 모드 On / Off' },
  displayLayout: { en: 'Display Layout', ko: '디스플레이 레이아웃' },
  hL7Setting: { en: 'HL7 Setting', ko: 'HL7 설정' },
  segmentFieldMapping: { en: 'Segment/Field Mapping', ko: 'Segment/Field 매핑' },
  labelCustomize: { en: 'Label Customize', ko: '라벨 커스터마이즈' },
  disconnected: { en: 'disconnected', ko: '연결 끊김' },
  filter: { en: 'Filter', ko: '필터' },
  patientInfoDelete: { en: 'Patient information has been deleted.', ko: '환자 정보가 삭제되었습니다.' },
  patientInfoDeleteFail: { en: 'Fail to delete patient information.', ko: '환자 정보 삭제에 실패했습니다.' },
  patientInfoDeleteConfirm: {
    en: 'Are you sure you want to delete patient information?',
    ko: '환자 정보를 삭제하시겠습니까?',
  },
  delete: { en: 'Delete', ko: '삭제' },
  search: { en: 'Search', ko: '검색' },
  connect: { en: 'Connect', ko: '연결' },
  firstName: { en: 'First Name', ko: '이름' },
  lastName: { en: 'Last Name', ko: '성' },
  birthDate: { en: 'Birth Date', ko: '생년월일' },
  sex: { en: 'Sex', ko: '성별' },
  info: { en: 'Info', ko: '기타 정보' },
  reports: { en: 'Reports', ko: '리포트' },
  patient: { en: 'Patient', ko: '환자' },
  period: { en: 'Period', ko: '기간' },
  reportType: { en: 'Report Type', ko: '리포트 타입' },
  reportFormat: { en: 'Report Format', ko: '리포트 포맷' },
  generateReport: { en: 'Generate Report', ko: '리포트 생성' },
  soundSetting: { en: 'Sound Setting', ko: '소리 설정' },
  alarmVolume: { en: 'Alarm Volume', ko: '알람 볼륨' },
  audioAlarmOff: { en: 'Audio Alarm OFF', ko: '오디오 알람 OFF' },
  soundOnForHighAlarm: { en: 'Sound-On For High Alarm', ko: 'High 알람 시 소리 켜기' },
  dualMonitor: { en: 'Dual Monitor', ko: '듀얼 모니터' },
  displayMode: { en: 'Display Mode', ko: '디스플레이 모드' },
  alarmMute: { en: 'Alarm Mute', ko: '알람 음소거' },
  patientList: { en: 'Patient List', ko: '환자 목록' },
  settings: { en: 'Settings', ko: '설정' },
  languageSetting: { en: 'Language Setting', ko: '언어 설정' },
  logout: { en: 'Logout', ko: '로그아웃' },
  totalPatient: { en: 'Total Patient Number : ', ko: '총 환자 수 : ' },
  maxSelectionLimit: { en: 'Max Selection Limit', ko: '최대 선택 가능 수' },
  number: { en: 'No.', ko: '번호' },
  error404: { en: 'Not Found', ko: '찾을 수 없음' },
  error404Message: { en: `Sorry, we can't find that page.`, ko: '죄송합니다. 해당 페이지를 찾을 수 없습니다.' },
  gotoLoginPage: { en: `Go to the Login Page`, ko: '로그인 화면으로 이동' },
  errorInvalidAccess: {
    en: 'A previous window is still open or was not closed properly.',
    ko: '기존 창이 열려있거나 비정상 종료되었습니다.',
  },
  errorInvalidAccessMessage: {
    en: 'Please try one of the following methods.',
    ko: '아래 방법 중 하나를 시도하여 주십시오.',
  },
  errorInvalidAccessMethodCloseExistTab: {
    en: 'Please use another window that already exists',
    ko: '이미 존재하는 다른 창을 사용하거나',
  },
  errorInvalidAccessMethodCloseCurrentTab: {
    en: `or click the 'Continue' button below to go to the login page.`,
    ko: `현재 창을 사용하려면, 아래의 '계속 진행하기' 버튼을 눌러 로그인 페이지로 이동하세요.`,
  },
  errorInvalidAccessRefresh: {
    en: 'The screen is inaccessible to url or was not closed properly.',
    ko: 'url로 접근할 수 없는 화면이거나 비정상 종료되었습니다.',
  },
  errorInvalidAccessMethodRefreshMainTab: {
    en: `Please refresh the main screen`,
    ko: `메인 화면을 새로고침하거나`,
  },
  errorInvalidAccessMethodCorrectUrl: {
    en: `or close the main screen and enter the correct url.`,
    ko: `메인 화면을 닫고 정확한 url을 입력하세요.`,
  },
  continue: { en: 'Continue', ko: '계속 진행하기' },
  edit: { en: 'Edit', ko: '수정' },
  race: { en: 'Race', ko: '인종' },
  male: { en: 'Male', ko: '남' },
  female: { en: 'Female', ko: '여' },
  register: { en: 'Register', ko: '등록' },
  save: { en: 'Save', ko: '저장' },
  deleteInfo: { en: 'Delete Info', ko: '정보 삭제' },
  alarmSoundSetting: { en: 'Alarm Sound Setting', ko: '알람 소리 설정' },
  messageAlarmOn: { en: 'Do you want to turn on the alarm?', ko: '알람 소리 설정을 켜시겠습니까?' },
  confirm: { en: 'Confirm', ko: '확인' },
  loginFailed: { en: 'Login Failed.', ko: '로그인에 실패하였습니다.' },
  loginFailedDescription: {
    en: 'You entered the wrong ID or password.',
    ko: '아이디 혹은 비밀번호를 잘못 입력했습니다.',
  },
  default: { en: 'Default', ko: '기본값' },
  noPermission: { en: 'You do not have permission', ko: '권한이 없습니다.' },
  expiredToken: { en: 'The token has expired.', ko: '토큰이 만료되었습니다.' },
  globalDefault: { en: 'Global Default', ko: '전체 설정값' },
  cellSetting: { en: 'Cell Setting', ko: '셀 설정' },
  systemSetting: { en: 'System Setting', ko: '시스템 설정' },
  mon: { en: 'Mon', ko: '월' },
  tue: { en: 'Tue', ko: '화' },
  wed: { en: 'Wed', ko: '수' },
  thu: { en: 'Thu', ko: '목' },
  fri: { en: 'Fri', ko: '금' },
  sat: { en: 'Sat', ko: '토' },
  sun: { en: 'Sun', ko: '일' },
  everyday: { en: 'everyday', ko: '매일' },
  am: { en: 'AM', ko: '오전' },
  pm: { en: 'PM', ko: '오후' },
  maxPatientNumber: { en: 'Max Patient Number', ko: '최대 환자 수' },
  selectAtLeastOneRefreshDay: {
    en: 'Select at least one refresh day',
    ko: '새로고침 요일을 하나 이상 선택해야 합니다.',
  },
  noFilters: { en: 'No filters', ko: '필터 없음' },
  deviceSerial: { en: 'Device Serial', ko: '시리얼 번호' },
  model: { en: 'Model', ko: '모델' },
  cellTitle: { en: 'Cell Title', ko: '셀 상단 제목' },
  patientInfo: { en: 'Patient Info', ko: '환자 정보' },
  selectDate: { en: 'Select Date', ko: '날짜 선택' },
  name: { en: 'Name', ko: '이름' },
  therapyMode: { en: 'Therapy Mode', ko: '치료 모드' },
  hours: { en: 'hours', ko: '시간' },
  minutes: { en: 'minutes', ko: '분' },
  seconds: { en: 'seconds', ko: '초' },
  askLogout: { en: 'Would you like to log out?', ko: '로그아웃 하시겠습니까?' },
  noData: { en: 'No data', ko: '데이터 없음' },
  resetParamSetting: {
    en: 'Initialize the settings with version upgrade',
    ko: '버전 업그레이드로 설정을 초기화 합니다.',
  },
  notification: {
    en: 'Notification',
    ko: '알림',
  },
  messageMaintananceAutoReload: {
    en: 'The screen has been refreshed due to system maintenance.',
    ko: '시스템 정비로 인하여 화면이 새로고침 되었습니다.',
  },
  deviceConnectionList: {
    en: 'Device Connection List',
    ko: '기기 연결 목록',
  },
  deviceAlias: {
    en: 'Device Alias',
    ko: '기기 별칭',
  },
  admission: {
    en: 'Admission',
    ko: '입원',
  },
  discharge: {
    en: 'Discharge',
    ko: '퇴원',
  },
  patientRegistrationRequired: {
    en: 'Patient registration required',
    ko: '환자 등록이 필요합니다.',
  },
  patientRegEdit: {
    en: 'Patient Reg/Edit',
    ko: '환자 등록/변경',
  },
  deviceRegistrationRequired: {
    en: 'Device registration required',
    ko: '기기 등록이 필요합니다.',
  },
  admissionDate: {
    en: 'Admission Dt.',
    ko: '입원일',
  },
  dischargeDate: {
    en: 'Discharge Dt.',
    ko: '퇴원일',
  },
  askAdmission: {
    en: 'Would you like to proceed with the admission?',
    ko: '입원 처리 하시겠습니까?',
  },
  askDischarge: {
    en: 'Would you like to discharge the patient?',
    ko: '퇴원 처리 하시겠습니까?',
  },
  fullAdmissionDate: {
    en: 'Admission Date',
    ko: '입원일',
  },
  fullDischargeDate: {
    en: 'Discharge Date',
    ko: '퇴원일',
  },
  globalColorScheme: {
    en: 'Global color scheme',
    ko: '전체 색상 테마',
  },
  paramColor: {
    en: 'Parameter color setting',
    ko: '파라미터 색상 설정',
  },
  darkMode: {
    en: 'Dark mode',
    ko: '어두운 모드',
  },
  brightMode: {
    en: 'Bright mode',
    ko: '밝은 모드',
  },
  settingParam: {
    en: 'Setting Param',
    ko: '설정 파라미터',
  },
  displayParam: {
    en: 'Display Param',
    ko: '디스플레이 파라미터',
  },
  wave: {
    en: 'Wave',
    ko: '웨이브',
  },
  trend: {
    en: 'Trend',
    ko: '트렌드',
  },
  patientRegistration: {
    en: 'Patient Registration',
    ko: '환자 등록',
  },
  updatePatientDetails: {
    en: 'Update Patient Details',
    ko: '환자 정보 수정',
  },
  patientRegSuccess: {
    en: 'Patient registered successfully.',
    ko: '환자가 등록되었습니다.',
  },
  existPatientId: {
    en: 'Duplicate patient ID',
    ko: '중복된 환자 아이디 입니다.',
  },
  invalidPatientIdFormat: {
    en: 'The ID is a combination of English letters and numbers of 2-10 characters and some special characters (._-).',
    ko: 'ID는 2-10자의 영문과 숫자와 일부 특수문자(._-)의 조합입니다.',
  },
  requiredPatientId: {
    en: 'Patient ID is required.',
    ko: '환자 아이디는 필수 입력사항입니다.',
  },
  requiredPatFirstName: {
    en: 'Firstname is required.',
    ko: '이름은 필수 입력사항입니다.',
  },
  requiredPatLastName: {
    en: 'Lastname is required.',
    ko: '성은 필수 입력사항입니다.',
  },
  requiredPatBirthdate: {
    en: 'Birth date is required.',
    ko: '생년월일은 필수 입력사항입니다.',
  },
  patientInfoUpdateSuccess: {
    en: 'Patient Information updated successfully.',
    ko: '환자 정보가 수정되었습니다.',
  },
  back: {
    en: 'Back',
    ko: '뒤로',
  },
  select: {
    en: 'Select',
    ko: '선택',
  },
  patientReg: {
    en: 'Patient Registration',
    ko: '환자 등록',
  },
  patientInfoEdit: {
    en: 'Patient Info Edit',
    ko: '환자 정보 수정',
  },
  admitSuccess: {
    en: 'Patient has been admitted.',
    ko: '환자 입원처리 되었습니다.',
  },
  dischargeSuccess: {
    en: 'Patient has been discharged.',
    ko: '환자 퇴원처리 되었습니다.',
  },
  unregisteredPatientId: {
    en: 'Unregistered patient ID',
    ko: '등록되지 않은 환자 아이디입니다.',
  },
  patientRegister: {
    en: 'Patient Reg.',
    ko: '환자 등록',
  },
  patientRegSelect: {
    en: 'Patient Reg/Select',
    ko: '환자 등록/선택',
  },
  others: {
    en: 'Others',
    ko: '기타',
  },
  clear: {
    en: 'Clear',
    ko: '초기화',
  },
  hourIsRequired: {
    en: 'Hour is required.',
    ko: '시간을 입력하세요.',
  },
  hourIsBetween: {
    en: 'Hour can be a number between 0 and 23.',
    ko: '시간을 0 ~ 23 사이의 숫자로 가능합니다.',
  },
  minutesAreRequired: {
    en: 'Minutes are required.',
    ko: '분은 필수 입력사항입니다.',
  },
  minutesAreBetween: {
    en: 'Minutes are numbers between 0 and 59.',
    ko: '분은 0에서 59 사이의 숫자입니다.',
  },
  secondsAreRequired: {
    en: 'Seconds are required.',
    ko: '초는 필수 입력사항입니다.',
  },
  secondsAreBetween: {
    en: 'Seconds are numbers between 0 and 59.',
    ko: '초은 0에서 59 사이의 숫자입니다.',
  },
  beforeTheCurrentTime: {
    en: 'Please enter a time before the current time.',
    ko: '현재 시간보다 이전 시간을 입력하세요.',
  },
  chooseTheTimeAfter: {
    en: 'Please enter the time after admission date',
    ko: '입원일 이후의 시간을 입력하세요.',
  },
  age: {
    en: 'age',
    ko: '만 나이',
  },
  years: {
    en: 'years',
    ko: '세',
  },
  man: {
    en: '',
    ko: '만',
  },
  askRemoteSetting: {
    en: 'Do you want to change the patient ID of the device?',
    ko: '기기의 환자 아이디를 변경하시겠습니까?',
  },
  apiError: {
    en: 'API response error',
    ko: 'API 응답 에러',
  },
  remoteSetupSuccessful: {
    en: 'The patient ID of the device has been successfully changed.',
    ko: '기기의 환자 아이디가 성공적으로 변경되었습니다.',
  },
  remoteSettingFail: {
    en: 'Failed to change the patient ID of the device.',
    ko: '기기의 환자 아이디 변경에 실패했습니다.',
  },
  changingPatientId: {
    en: 'Changing the patient ID of the device.',
    ko: ' 기기의 환자아이디를 변경중입니다.',
  },
  currentPatientId: {
    en: 'Current patient ID of the device',
    ko: '현재 기기의 환자 아이디',
  },
  modifyPatientId: {
    en: 'Patient ID to change',
    ko: '변경할 환자 아이디',
  },
  networkError: {
    en: 'Network Error',
    ko: '네트워크 에러',
  },
  inactiveDevice: {
    en: 'Inactive Device',
    ko: '비활성화된 장치입니다.',
  },
  inactivePatient: {
    en: 'Inactive Patient',
    ko: '비활성화된 환자입니다.',
  },
  alreadyConnected: {
    en: 'This device is already connected.',
    ko: '이미 연결된 장치입니다.',
  },
  alreadyDisconnected: {
    en: 'This device is already disconnected.',
    ko: '이미 연결 해제된 장치입니다.',
  },
  failToConnectionList: {
    en: 'Failed to get device connection list.',
    ko: '기기 연결 정보 목록을 불러오지 못했습니다.',
  },
  fail: {
    en: 'Fail',
    ko: '실패',
  },
  loggedOutByAccessingTheSameId: {
    en: 'Logged out by using the same ID from another location.',
    ko: '다른 장소에서 해당 아이디로 접속하여 로그아웃됩니다.',
  },
  ssn: {
    en: 'Social security number',
    ko: '주민번호',
  },
  serverError: {
    en: 'Server error, please contact your administrator.',
    ko: '서버 오류입니다. 관리자에게 문의하세요.',
  },
  remoteSettingSuccessful: {
    en: 'Successfully changed.',
    ko: '성공적으로 변경되었습니다.',
  },
  remoteSettingFailed: {
    en: 'Failed to change.',
    ko: '변경에 실패했습니다.',
  },
  loadingRemoteSetting: {
    en: 'Changing the setting parameter of the device.',
    ko: ' 기기의 설정값을 변경중입니다.',
  },
  settingValueChanged: {
    en: 'The setting param have been changed on the device.',
    ko: '기기에서 설정값이 변경되었습니다.',
  },
  prevValue: {
    en: 'Previous value',
    ko: '이전 값',
  },
  changeValue: {
    en: 'Change value',
    ko: '변경 값',
  },
  curValue: {
    en: 'Current Value',
    ko: '현재 값',
  },
  remoteSettingConfirm1: {
    en: 'Do you want to change the setting',
    ko: '기기의 ',
  },
  remoteSettingConfirm2: {
    en: ' of the device?',
    ko: '설정값을 변경하시겠습니까?',
  },
  theme: { en: 'Theme', ko: '테마' },
  maximumNumberOfDevice: { en: 'The maximum number of selectable devices is', ko: '선택할 수 있는 최대 기기 수는' },
  is: { en: '.', ko: '입니다.' },
  errorUncatched: { en: 'Something went wrong', ko: '오류가 발생하였습니다.' },
  sort: { en: 'Sorting', ko: '정렬' },
  'patient-name-asc': { en: 'Patient Name ASC', ko: '환자 이름 오름차순' },
  'patient-name-desc': { en: 'Patient Name DESC', ko: '환자 이름 내림차순' },
  'patient-id-asc': { en: 'Patient ID ASC', ko: '환자 아이디 오름차순' },
  'patient-id-desc': { en: 'Patient ID DESC', ko: '환자 아이디 내림차순' },
  'device-alias-asc': { en: 'Device Alias ASC', ko: '기기 별칭 오름차순' },
  'device-alias-desc': { en: 'Device Alias DESC', ko: '기기 별칭 내림차순' },
  'bed-id-asc': { en: 'Bed ID ASC', ko: '침대 번호 오름차순' },
  'bed-id-desc': { en: 'Bed ID DESC', ko: '침대 번호 내림차순' },
  'sort-disabled': { en: 'Disable sorting', ko: '정렬 비활성화' },
  changeParamConfirm: {
    en: 'Changes will be discarded. Do you want to continue?',
    ko: '변경한 내용이 취소됩니다. 계속 진행하시겠습니까?',
  },
  remoteSettingConfirm1Alarm: {
    en: 'Do you want to change the alarm setting',
    ko: '기기의 ',
  },
  remoteSettingConfirm2Alarm: {
    en: ' of the device?',
    ko: '알람값을 변경하시겠습니까?',
  },
  remoteSettingInProgress: {
    en: 'Remote setting is in progress.',
    ko: '원격 설정이 진행중입니다.',
  },
  defaultSort: { en: 'Default Sort Option', ko: '기본 정렬' },
  custom: { en: 'Custom', ko: '사용자 정의' },
  chooseWave: { en: 'View Chart', ko: '차트 표시' },
  applyAll: { en: 'Apply all', ko: '전체 적용' },
  enterParam: { en: 'Enter parameter', ko: '파라미터를 입력하세요' },
  ok: { en: 'OK', ko: '확인' },
  autoPatientGrouping: { en: 'Patient Grouping', ko: '환자 분류' },
  active: { en: 'Active', ko: '활성' },
  inactive: { en: 'Inactive', ko: '비활성' },
  rearrangement: { en: 'Rearrangement', ko: '재정렬' },
  browserCheck: { en: 'Browser Check', ko: '브라우저 확인' },
  recommendChrome: { en: 'Chrome browser is recommended.', ko: '크롬 브라우저를 권장합니다.' },
  checkMixedContent: {
    en: 'Please check the browser mixed content(Insecure content) permission settings.',
    ko: '브라우저의 mixed content(Insecure content) 허용 설정을 확인해주세요.',
  },
  standby: {
    en: 'Standby mode',
    ko: '대기 모드',
  },
  settingValue: { en: 'Setting Values', ko: '설정값' },
  nibpStart: { en: 'Start NIBP measurement', ko: 'NIBP 측정 시작' },
  nibpStop: { en: 'Stop NIBP measurement', ko: 'NIBP 측정 정지' },
  cellHeaderSize: { en: 'Cell Titlebar Size', ko: '셀 제목바 크기' },
  cellHeaderBoxSize: { en: 'Cell Title Box Size', ko: '셀 제목 박스 크기' },
  noDisplay: { en: 'No Display', ko: '표시 없음' },
  showAdmitIcon: { en: 'Show admit icon', ko: '입원 아이콘 표시' },
  isBpMeanVisible: { en: 'Show Blood Pressure Mean', ko: '혈압 Mean값 표시' },
  invalidLoginInfo: { en: 'Invalid ID, Password', ko: '아이디, 패스워드를 확인하세요' },
  deviceUpdate: { en: 'Update Device Info', ko: '기기 정보 수정' },
  deviceInfo: { en: 'Device Info', ko: '기기 정보' },
  deviceInfoEdit: { en: 'Device Info Edit', ko: '기기 정보 수정' },
  deviceInfoUpdateSuccess: {
    en: 'Device Information updated successfully.',
    ko: '기기 정보가 수정되었습니다.',
  },
  autoAdjustment: { en: 'Auto adjustment', ko: '자동 조정' },
  customSelect: { en: 'Custom', ko: '직접 설정' },
  datetime: { en: 'Date time', ko: '일시' },
  value: { en: 'Value', ko: '값' },
  print: { en: 'Print', ko: '인쇄' },
  min: { en: 'min', ko: '분' },
  history: { en: 'History', ko: '기록' },
  printCurrentRange: { en: 'Print current range', ko: '현재 구간 인쇄' },
  rangeSelection: { en: 'Range selection', ko: '구간 선택' },
  selectedTime: { en: 'Selected time', ko: '선택 시간' },
  previous: { en: 'Previous', ko: '이전' },
  sec: { en: 'sec', ko: '초' },
  after: { en: 'after', ko: '이후' },
  timeSelect: { en: 'Time Select', ko: '시간 선택' },
  referenceTime: { en: 'Reference time', ko: '기준 시간' },
  alarm: { en: 'Alarm', ko: '알람' },
  inValidPrintRange: { en: 'Invalid print range', ko: '유효하지 않은 인쇄 구간 입니다.' },
  allLevel: { en: 'All Level', ko: '전체 레벨' },
  printDate: { en: 'Print Date', ko: '출력일시' },
  ageCapital: {
    en: 'Age',
    ko: '만 나이',
  },
  connectionDatetime: { en: 'Connection Dt.', ko: '연결일시' },
  newPatient: {
    en: 'New patient',
    ko: '신규 환자',
  },
  existingPatient: { en: 'Existing patient', ko: '기존 환자' },
  connectPatient: { en: 'Connect patient', ko: '환자 연결' },
  connectedPatient: { en: 'The patient has been successfully connected.', ko: '환자가 성공적으로 연결되었습니다.' },
  loadingPdf: { en: 'Preparing report', ko: '보고서 준비중입니다.' },
  wait: { en: 'Please wait for a moment.', ko: '잠시만 기다려주세요.' },
  processPdf: { en: 'Generating report', ko: '보고서 생성 중입니다.' },
  showPatientConnectionIcon: { en: 'Show Patient Connection icon', ko: '환자 연결 아이콘 표시' },
  review: { en: 'Review', ko: '리뷰' },
  enterKeyword: { en: 'Please enter your keyword to search', ko: '검색어를 입력하세요' },
  connectionEndDatetime: { en: 'Connection End Dt.', ko: '연결종료일시' },
  beforeTheLimitTime: {
    en: 'Please enter a time before the connection time.',
    ko: '연결 종료 시간보다 이전 시간을 입력하세요.',
  },
  afterTheLimitTime: {
    en: 'Please enter a time after the current time.',
    ko: '연결 시간보다 이후 시간을 입력하세요.',
  },
  occurredBeforeThePeriod: { ko: '기간 이전 발생', en: 'occurred before the period' },
  alarmsOccurredBeforeThePeriod: { ko: '기간 이전 발생 알람', en: 'Alarms occurred before the period' },
  clearedTime: { en: 'Cleared Date time', ko: '종료일시' },
  fromTime: { en: 'From time', ko: '시작 시간' },
  toTime: { en: 'To time', ko: '종료 시간' },
  selectAfterTheFromTime: {
    en: 'Please select a time after the from time',
    ko: '시작 시간 이후의 시간을 선택하세요.',
  },
  selectBeforeTheToTime: {
    en: 'Please select a time before the to time',
    ko: '종료 시간 이전의 시간을 선택하세요.',
  },
  applyPatientConnectionDate: {
    en: 'Apply patient connection date',
    ko: '환자 연결 시간 적용',
  },
  noPatientIsConnected: {
    en: '(No patient is connected.)',
    ko: '(연결된 환자가 없습니다.)',
  },
  mqttConnectionFailed: {
    en: 'ws connection failed.',
    ko: 'ws 연결에 실패하였습니다.',
  },
  small: {
    en: 'Small',
    ko: '작게',
  },
  large: {
    en: 'Large',
    ko: '크게',
  },
  cellHeaderBoxColor: { en: 'Cell Title Box Color', ko: '셀 제목 박스 색상' },
  fontColor: { en: 'Font Color', ko: '글자 색상' },
  bgColor: { en: 'Background Color', ko: '배경 색상' },
  fitTitleBar: { en: 'Fit Titlebar size', ko: '셀 제목바 크기에 맞춤' },
};

export default languages;
